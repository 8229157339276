import { useState, useEffect } from "react";
import { Box, Button, Paper } from "@mui/material";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { selectDefaultStyles } from "../../../../../components/selectStyles/stylev3";
import CustomDatePickerInput from "../../../../../components/datePickerInput";
import ProductSummary from "./productSummary/productSummary";
import useWindowDimensions from "../../../../../components/windowSize";
import AddBoxIcon from "@mui/icons-material/AddBox";
import InputField from "../../../../../components/input";
import managementService from "../../../../../services/managementService";
import OptionelPrices from "./optionelPrices/OptionelPrices";
import Module from "./module";
import moment from "moment";
import { useToast } from "../../../../../components/snackbar";
import { GreenSwitch } from "../../../../../components/switch/greenSwitch";

const discountsSetup = [];
for (let i = 0; i <= 100; i++) {
  if (i % 5 === 0) {
    let discount = {
      value: i + " %",
      key: i,
    };
    discountsSetup.push(discount);
  }
}

const readyForSaleOptions = [
  { value: true, label: "Evet" },
  { value: false, label: "Hayır" },
];

export default function Product({
  selectedProduct,
  selectedPackageProducts,
  packageId,
  setSelectedPackageProducts,
  setOpenProduct,
  setSelectedProduct,
}) {
  const { width } = useWindowDimensions();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [selectedKdv, setSelectedKdv] = useState(null);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [productTypes, setProductTypes] = useState({});
  const [selectedDiscountPermission, setSelectedDiscountPermission] = useState(
    []
  );
  const [selectedDiscountAgentPermission, setSelectedDiscountAgentPermission] =
    useState([]);
  const [selectedDiscountRenewPermission, setSelectedDiscountRenewPermission] =
    useState([]);
  const [isReadyForSale, setIsReadyForSale] = useState(false);
  const [selectedProductType, setSelectedProductType] = useState(null);
  const [updatedModules, setUpdatedModules] = useState([]);
  const { alert, success } = useToast();
  const [productPlans, setProductPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const [productData, setProductData] = useState({
    id: null,
    payment_type: "",
    product_type: "",
    kdv: "",
    name: "",
    price: "",
    description: "",
    ready_for_sale: false,
    package: null,
    discount_value: 0,
    price_description: "",
    modules: [],
    discount_permission_array: [],
    discount_expires_at: null,
    link: "",
    is_active: false,
    price_action: "",
    optional_price: false,
    optional_price_value: [],
    header_summary: "",
    summaries: [],
    application_description: "",
    product_plan_settings: [],
  });

  const getProductTypes = async () => {
    const response = await managementService.get_product_types();
    setProductTypes(response);
  };

  useEffect(() => {
    if (productTypes.included_types) {
      productTypes.included_types.push({ key: "text", value: "Yazı" });
    }
  }, [productTypes]);

  useEffect(() => {
    setProductData(selectedProduct);
    setUpdatedModules(selectedProduct.modules);

    if (productTypes.payment_types) {
      productTypes.payment_types.forEach((type) => {
        if (type.value === selectedProduct.payment_type) {
          setSelectedPaymentType(type);
        }
      });
    }

    if (productTypes.kdv_types) {
      productTypes.kdv_types.forEach((type) => {
        if (type.value === selectedProduct.kdv) {
          setSelectedKdv(type);
        }
      });
    }

    discountsSetup.forEach((dis) => {
      if (dis.key === selectedProduct.discount_value) {
        setSelectedDiscount(dis);
      }
    });

    if (selectedProduct.discount_permission_array) {
      let arr = [];
      selectedProduct.discount_permission_array.forEach((dis) => {
        const option = { value: dis + " %", key: dis };
        arr.push(option);
      });
      setSelectedDiscountPermission(arr);
    }

    if (selectedProduct.agent_discount_permission_array) {
      let arr = [];
      selectedProduct.agent_discount_permission_array.forEach((dis) => {
        const option = { value: dis + " %", key: dis };
        arr.push(option);
      });
      setSelectedDiscountAgentPermission(arr);
    }

    if (selectedProduct.renew_discount_permission_array) {
      let arr = [];
      selectedProduct.renew_discount_permission_array.forEach((dis) => {
        const option = { value: dis + " %", key: dis };
        arr.push(option);
      });
      setSelectedDiscountRenewPermission(arr);
    }

    readyForSaleOptions.map((option) => {
      if (option.value === selectedProduct.ready_for_sale) {
        setIsReadyForSale(option);
      }
    });

    if (selectedProduct.discount_expires_at) {
      setSelectedDate(new Date(selectedProduct.discount_expires_at));
    } else {
      setSelectedDate(new Date());
    }

    if (selectedProduct.product_plan_settings.length > 0) {
      const plans = selectedProduct.product_plan_settings.map(
        (plan) => plan.product_plan
      );
      setProductPlans(plans);
    }

    setSelectedProductType(selectedProduct.product_type);
  }, [selectedProduct, productTypes]);

  useEffect(() => {
    if (selectedPlan) {
      const selectedPlanProgram = selectedProduct.product_plan_settings.find(
        (plan) => plan.product_plan.id === selectedPlan.id
      );
      setSelectedDiscount({
        key: selectedPlanProgram.discount_value,
        value: selectedPlanProgram.discount_value + " %",
      });

      setSelectedDiscountPermission(
        selectedPlanProgram.discount_permission_array.map((dis) => {
          return { key: dis, value: dis + " %" };
        })
      );

      setSelectedDiscountAgentPermission(
        selectedPlanProgram.agent_discount_permission_array.map((dis) => {
          return { key: dis, value: dis + " %" };
        })
      );

      setSelectedDiscountRenewPermission(
        selectedPlanProgram.renew_discount_permission_array.map((dis) => {
          return { key: dis, value: dis + " %" };
        })
      );
    }
  }, [selectedPlan]);

  useEffect(() => {
    getProductTypes();
  }, []);

  const handleAddDescription = () => {
    const newFeature = { description: "" };
    const updatedSummaries = [...productData.summaries, newFeature];
    setProductData({ ...productData, summaries: updatedSummaries });
  };

  const handleAddModule = () => {
    setUpdatedModules([
      ...updatedModules,
      {
        name: "",
        modules: [],
        description: "",
      },
    ]);
  };

  const handleProductActivity = (e) => {
    setProductData({ ...productData, is_active: e.target.checked });
  };

  const handleSaveProduct = async () => {
    if (selectedPlan) {
      const selectedPlanSettings = selectedProduct.product_plan_settings.find(
        (plan) => plan.product_plan.id === selectedPlan.id
      );
      selectedPlanSettings.discount_value = selectedDiscount.key;
      selectedPlanSettings.discount_permission_array =
        selectedDiscountPermission.map((dis) => dis.key);
      selectedPlanSettings.agent_discount_permission_array =
        selectedDiscountAgentPermission.map((dis) => dis.key);
      selectedPlanSettings.renew_discount_permission_array =
        selectedDiscountRenewPermission.map((dis) => dis.key);
      selectedPlanSettings.discount_expires_at = moment(selectedDate).format(
        "YYYY-MM-DDTHH:mm:ssZ"
      );
      productData.product_plan_settings = selectedProduct.product_plan_settings;
    }

    let product = {
      description: productData.description,
      header_summary: productData.header_summary,
      summaries: productData.summaries,
      is_active: productData.is_active,
      kdv: selectedKdv.key,
      link: productData.link,
      name: productData.name,
      optional_price:
        productData.optional_price_value &&
        productData.optional_price_value.length > 0
          ? true
          : false,
      optional_price_value: productData.optional_price_value,
      application_description: productData.application_description,
      price_action: productData.price_action,
      package: packageId,
      price: productData.price,
      price_description: productData.price_description,
      payment_type: selectedPaymentType.key,
      product_type: selectedProductType.key,
      ready_for_sale: isReadyForSale.value,
      product_plan_settings: productData.product_plan_settings,
    };

    if (!productData.id) {
      await managementService.add_product_to_package(packageId, product);
      success("Ürün başarıyla oluşturuldu.");
    } else {
      await managementService.patch_product_detail(
        packageId,
        productData.id,
        product
      );
      success("Ürün başarıyla kaydedildi.");
    }
    const resProducts = await managementService.get_package_products(packageId);
    setSelectedPackageProducts(resProducts);
    setOpenProduct(false);
  };

  const handleSaveModules = async () => {
    updatedModules.forEach((module) => {
      module.modules.forEach((mod) => {
        if (mod.included === "optional") {
          mod.optional_product = mod.optional_product.id;
        }
      });
    });

    let dataModules = {
      data: updatedModules,
    };

    await managementService
      .add_modules_to_product(productData.id, dataModules)
      .then((response) => {
        const res = managementService.get_product_detail(
          selectedProduct.package,
          selectedProduct.id
        );
        setSelectedProduct(res);
        success("Modüller başarıyla eklendi");
        setOpenProduct(false);
      })
      .catch((err) => {
        if (err.response.data) {
          alert(err.response.data.message);
          return;
        }
        alert("Bir Hata oluştu");
      });
  };

  return (
    <Box sx={{ marginBottom: 2 }}>
      <Paper
        style={{
          padding: 20,
          borderRadius: 8,
          marginBottom: 20,
          background: "none",
        }}
        elevation={3}
      >
        <Paper
          style={{
            padding: 20,
            borderRadius: 8,
            marginBottom: 20,
            background: "none",
          }}
          elevation={3}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <GreenSwitch
              checked={productData?.is_active}
              onChange={handleProductActivity}
            />

            <Box sx={{ marginRight: 2 }}>
              <InputField
                label="Ürün"
                fieldName="name"
                formData={productData}
                setFormData={setProductData}
                fieldValue={productData?.name}
              />
            </Box>

            <Box>
              <span
                style={{
                  color: "#585858",
                  fontFamily: "segoeuiRg",
                  fontSize: 14,
                  fontWeight: 700,
                  marginBottom: 4,
                }}
              >
                Ürün Tipi
              </span>

              <Select
                styles={{ ...selectDefaultStyles, height: "555px" }}
                options={productTypes?.product_types}
                value={selectedProductType}
                zIndex={500}
                isSearchable={false}
                hoverColor={"black"}
                selectWidth={width > 1200 ? 220 : 140}
                menuColor={"#fff"}
                color={"#000"}
                placeholder={""}
                onChange={(e) => {
                  setSelectedProductType(e);
                }}
                getOptionValue={(option) => option.key}
                getOptionLabel={(option) => option.value}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <Box sx={{ marginRight: 2, marginTop: 2 }}>
              <InputField
                label="Ürün Açıklama"
                fieldName="description"
                formData={productData}
                setFormData={setProductData}
                fieldValue={productData?.description}
              />
            </Box>

            <Box sx={{ marginRight: 2, marginTop: 2 }}>
              <InputField
                label="Ürün Link"
                fieldName="link"
                formData={productData}
                setFormData={setProductData}
                fieldValue={productData?.link}
              />
            </Box>

            <Box sx={{ marginRight: 2, marginTop: 2 }}>
              <InputField
                label="Ürün Link Açıklama"
                fieldName="application_description"
                formData={productData}
                setFormData={setProductData}
                fieldValue={productData?.application_description}
              />
            </Box>

            <Box sx={{ marginRight: 2, marginTop: 2 }}>
              <InputField
                label="Fiyat"
                fieldName="price"
                formData={productData}
                setFormData={setProductData}
                fieldValue={productData?.price}
              />
            </Box>

            <Box sx={{ marginRight: 2, marginTop: 2 }}>
              <span
                style={{
                  color: "#585858",
                  fontFamily: "segoeuiRg",
                  fontSize: 14,
                  fontWeight: 700,
                  marginBottom: 4,
                }}
              >
                Ödeme Türü
              </span>

              <Select
                styles={{ ...selectDefaultStyles, height: "555px" }}
                options={productTypes?.payment_types}
                value={selectedPaymentType}
                zIndex={500}
                isSearchable={false}
                hoverColor={"black"}
                selectWidth={width > 1200 ? 220 : 140}
                menuColor={"#fff"}
                color={"#000"}
                placeholder={""}
                onChange={(e) => {
                  setSelectedPaymentType(e);
                }}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => option.value}
              />
            </Box>

            <Box sx={{ marginRight: 2, marginTop: 2 }}>
              <span
                style={{
                  color: "#585858",
                  fontFamily: "segoeuiRg",
                  fontSize: 14,
                  fontWeight: 700,
                  marginBottom: 4,
                }}
              >
                Kdv
              </span>
              <Select
                styles={{ ...selectDefaultStyles, height: "555px" }}
                options={productTypes?.kdv_types}
                value={selectedKdv}
                zIndex={300}
                isSearchable={false}
                hoverColor={"black"}
                selectWidth={width > 1200 ? 220 : 140}
                menuColor={"#fff"}
                color={"#000"}
                placeholder={""}
                onChange={(e) => {
                  setSelectedKdv(e);
                }}
                getOptionValue={(option) => option.key}
                getOptionLabel={(option) => option.value}
              />
            </Box>

            <Box sx={{ marginRight: 2, marginTop: 2 }}>
              <span
                style={{
                  color: "#585858",
                  fontFamily: "segoeuiRg",
                  fontSize: 14,
                  fontWeight: 700,
                  marginBottom: 4,
                }}
              >
                Satışa Hazır
              </span>
              <Select
                styles={{ ...selectDefaultStyles, height: "555px" }}
                options={readyForSaleOptions}
                isSearchable={false}
                value={isReadyForSale}
                zIndex={200}
                hoverColor={"black"}
                selectWidth={width > 1200 ? 220 : 140}
                menuColor={"#fff"}
                color={"#000"}
                placeholder={"Seçiniz"}
                onChange={(e) => {
                  setIsReadyForSale(e);
                }}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => option.label}
              />
            </Box>
          </Box>
        </Paper>

        <Paper
          style={{ padding: 20, borderRadius: 8, marginBottom: 20 }}
          elevation={3}
        >
          <Box>
            <span
              style={{
                color: "#585858",
                fontFamily: "segoeuiRg",
                fontSize: 14,
                fontWeight: 700,
                marginBottom: 4,
              }}
            >
              Paket Planı
            </span>
            <Select
              menuPosition="fixed"
              menuPortalTarget={document.body}
              styles={{ ...selectDefaultStyles, height: "555px" }}
              options={productPlans}
              isSearchable={false}
              value={selectedPlan}
              zIndex={9999}
              hoverColor={"black"}
              selectWidth={220}
              menuColor={"#fff"}
              color={"#000"}
              placeholder={"Paket Planı Seçiniz"}
              onChange={(e) => {
                setSelectedPlan(e);
              }}
              getOptionValue={(option) => option.key}
              getOptionLabel={(option) => option.name}
            />
          </Box>
          {selectedPlan && (
            <div>
              <Box
                sx={{
                  display: "flex",
                  background: "#efefef",
                  padding: 1,
                  marginBottom: 2,
                }}
              >
                <Box sx={{ marginRight: 2, marginTop: 2 }}>
                  <span
                    style={{
                      color: "#585858",
                      fontFamily: "segoeuiRg",
                      fontSize: 14,
                      fontWeight: 700,
                      marginBottom: 4,
                    }}
                  >
                    İndirim
                  </span>
                  <Select
                    styles={{ ...selectDefaultStyles, height: "555px" }}
                    options={discountsSetup}
                    isSearchable={false}
                    value={selectedDiscount}
                    zIndex={600}
                    hoverColor={"black"}
                    selectWidth={width > 1200 ? 220 : 140}
                    menuColor={"#fff"}
                    color={"#000"}
                    placeholder={"0%"}
                    onChange={(e) => {
                      setSelectedDiscount(e);
                    }}
                    getOptionValue={(option) => option.key}
                    getOptionLabel={(option) => option.value}
                  />
                </Box>

                <Box
                  sx={{
                    marginRight: 2,
                    marginTop: 2,
                    zIndex: 250,
                    position: "relative",
                  }}
                >
                  <div>
                    <span
                      style={{
                        color: "#585858",
                        fontFamily: "segoeuiRg",
                        fontSize: 14,
                        fontWeight: 700,
                        marginBottom: 4,
                      }}
                    >
                      İndirim Tarihi
                    </span>
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      fixedHeight="45px"
                      selected={selectedDate ? new Date(selectedDate) : null}
                      minDate={new Date()}
                      onChange={(update) => {
                        setSelectedDate(update);
                      }}
                      customInput={
                        <CustomDatePickerInput
                          selectWidth={width > 1200 ? 230 : 140}
                        />
                      }
                      locale="tr"
                    />
                  </div>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  background: "#efefef",
                  padding: 1,
                  marginBottom: 2,
                }}
              >
                <Box sx={{ marginRight: 2 }}>
                  <span
                    style={{
                      color: "#585858",
                      fontFamily: "segoeuiRg",
                      fontSize: 14,
                      fontWeight: 700,
                      marginBottom: 4,
                    }}
                  >
                    Yönetici İndirim Yetki
                  </span>
                  <Select
                    styles={{
                      ...selectDefaultStyles,
                      height: "555px",
                      backgroundColor: "red",
                    }}
                    options={discountsSetup}
                    isMulti
                    isSearchable={false}
                    value={selectedDiscountPermission}
                    zIndex={200}
                    hoverColor={"black"}
                    selectWidth={width > 1200 ? 220 : 140}
                    menuColor={"#fff"}
                    color={"#000"}
                    placeholder={"0%"}
                    onChange={(e) => {
                      setSelectedDiscountPermission(e);
                    }}
                    getOptionValue={(option) => option.key}
                    getOptionLabel={(option) => option.value}
                  />
                </Box>

                <Box sx={{ marginRight: 2 }}>
                  <span
                    style={{
                      color: "#585858",
                      fontFamily: "segoeuiRg",
                      fontSize: 14,
                      fontWeight: 700,
                      marginBottom: 4,
                    }}
                  >
                    Agent İndirim Yetki
                  </span>
                  <Select
                    styles={{
                      ...selectDefaultStyles,
                      height: "555px",
                      backgroundColor: "red",
                    }}
                    options={discountsSetup}
                    isMulti
                    isSearchable={false}
                    value={selectedDiscountAgentPermission}
                    zIndex={200}
                    hoverColor={"black"}
                    selectWidth={width > 1200 ? 220 : 140}
                    menuColor={"#fff"}
                    color={"#000"}
                    placeholder={"0%"}
                    onChange={(e) => {
                      setSelectedDiscountAgentPermission(e);
                    }}
                    getOptionValue={(option) => option.key}
                    getOptionLabel={(option) => option.value}
                  />
                </Box>

                <Box sx={{ marginRight: 2 }}>
                  <span
                    style={{
                      color: "#585858",
                      fontFamily: "segoeuiRg",
                      fontSize: 14,
                      fontWeight: 700,
                      marginBottom: 4,
                    }}
                  >
                    Sözleşme Yenileme İndirim Yetki
                  </span>
                  <Select
                    styles={{
                      ...selectDefaultStyles,
                      height: "555px",
                      backgroundColor: "red",
                    }}
                    options={discountsSetup}
                    isMulti
                    isSearchable={false}
                    value={selectedDiscountRenewPermission}
                    zIndex={200}
                    hoverColor={"black"}
                    selectWidth={width > 1200 ? 220 : 140}
                    menuColor={"#fff"}
                    color={"#000"}
                    placeholder={"0%"}
                    onChange={(e) => {
                      setSelectedDiscountRenewPermission(e);
                    }}
                    getOptionValue={(option) => option.key}
                    getOptionLabel={(option) => option.value}
                  />
                </Box>
              </Box>

              <Box sx={{ marginRight: 2, marginTop: 2 }}>
                <Button
                  style={{
                    backgroundColor: "#f53f62",
                    color: "#fff",
                    fontFamily: "segoeuiRg",
                    width: 200,
                    marginTop: 15,
                    display: "flex",
                    justifyContent: "center",
                    height: 40,
                    textTransform: "capitalize",
                    marginLeft: "auto",
                  }}
                  size="small"
                  onClick={handleSaveProduct}
                >
                  Kaydet
                </Button>
              </Box>
            </div>
          )}
        </Paper>

        <OptionelPrices
          productData={productData}
          setProductData={setProductData}
        />
        <ProductSummary
          name={productData?.header_summary}
          summaries={productData?.summaries}
          productData={productData}
          setProductData={setProductData}
          handleAddDescription={handleAddDescription}
        />

        <Box sx={{ marginBottom: 4 }}>
          <Button
            style={{
              backgroundColor: "#f53f62",
              color: "#fff",
              fontFamily: "segoeuiRg",
              width: 200,
              marginTop: 15,
              display: "flex",
              justifyContent: "center",
              height: 40,
              textTransform: "capitalize",
              margin: "auto",
            }}
            size="small"
            onClick={handleSaveProduct}
          >
            Ürünü Kaydet
          </Button>
        </Box>
      </Paper>

      {productData.id && (
        <>
          <Box sx={{ padding: 1, background: "#e1e1e1" }}>
            <h3 style={{ textAlign: "center" }}>Moduller</h3>
            {updatedModules.map((item, index) => {
              return (
                <Module
                  key={index}
                  module={item}
                  index={index}
                  productData={productData}
                  setProductData={setProductData}
                  selectedPackageProducts={selectedPackageProducts}
                  updatedModules={updatedModules}
                  setUpdatedModules={setUpdatedModules}
                  productTypes={productTypes}
                />
              );
            })}
          </Box>
          <Box
            sx={{
              background: "#fff",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              padding: 1,
              width: "fit-content",
              marginLeft: "auto",
              marginTop: 2,
            }}
            onClick={handleAddModule}
          >
            <span
              style={{
                marginRight: 10,
                display: "block",
                color: "#585858",
                fontFamily: "segoeuiRg",
                fontSize: 16,
                fontWeight: 700,
              }}
            >
              Modul Ekle
            </span>
            <AddBoxIcon style={{ color: "red" }} />
          </Box>

          <Box>
            <Button
              style={{
                backgroundColor: "#f53f62",
                color: "#fff",
                fontFamily: "segoeuiRg",
                width: 200,
                marginTop: 15,
                display: "flex",
                justifyContent: "center",
                height: 40,
                textTransform: "capitalize",
                margin: "auto",
              }}
              size="small"
              onClick={handleSaveModules}
            >
              Modulleri Kaydet
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
