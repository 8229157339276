import React, { useState, useContext, useEffect, useReducer } from "react";

import { RestaurantContext } from "../../../contexts/restaurantContext";
import { LoginContext } from "../../../contexts/loginContext";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  InputBase,
  Paper,
} from "@mui/material";
import Select from "react-select";
import { selectDefaultStyles } from "../../../components/selectStyles/stylev3";
import { data, freeDays, offerCount } from "../../../utils/constants";
import { pink } from "@mui/material/colors";
import { useParams } from "react-router-dom";
import moment from "moment";
import { ArrowRightAltOutlined, Paid, RemoveRedEye } from "@mui/icons-material";
import PayedModal from "../../../components/modals/payedModal";
import OfferModalV2 from "../../../components/modals/offerModal";
import OfferPriceChangeModal from "../../../components/modals/offerPriceChange";
import { makeStyles } from "@mui/styles";
import useDebounce from "../../../components/debounce";
import { useToast } from "../../../components/snackbar";
import PlanModal from "./components/planModal";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      borderRadius: 10,
    },
    "& .PrivateNotchedOutline-root-15.MuiOutlinedInput-notchedOutline": {
      borderColor: "gray",
      borderWidth: 0,
      borderRadius: 10,
    },
    "& .MuiOutlinedInput-input": {
      marginTop: 6,
      marginLeft: 6,
      color: "black",
      borderRadius: 10,
    },
    "& .css-1wu7ecg-MuiSvgIcon-root-MuiSelect-icon": {
      color: "black",
    },
  },
});

export const Offers = () => {
  const classes = useStyles();
  let { id } = useParams();
  const { alert } = useToast();
  const [offerProductsObj, setOfferProductsObj] = useState(null);
  const [selectedCard, setSelectedCard] = useState("active");
  const [offerHistoryDetailObj, setOfferHistoryDetailObj] = useState(null);
  const [payedModal, setPayedModal] = useState("");
  const [disableOffer, setDisableOffer] = useState(false);
  const [offerModal, setOfferModal] = useState(false);
  const [partialPayment, setPartialPayment] = useState("");
  const {
    offerProductListFunc,
    offerProductList,
    offerProducts,
    offerBasketFunc,
    offerBasketChangeFunc,
    getOfferBasketFunc,
    createOfferFunc,
    offerHistoriesListFunc,
    offerHistoriesList,
    offerHistoryDetailFunc,
    offerHistoryDetail,
    sendOfferPopupFunc,
    partialPaymentInfoFunc,
    partialPaymentCreateFunc,
    partialPaymentInfo,
    partialPaymentDeleteFunc,
    offerBasketPlan,
  } = useContext(RestaurantContext);
  const [offerPriceChangeModal, setOfferPriceChangeModal] = useState(false);
  const [financeOfferRow, setFinanceOfferRow] = useState(null);
  const [selectedPaymentType, setselectedPaymentType] = useState(null);
  const [kontorQuantity, setKontorQuantity] = useState({
    id: null,
    count: 0,
  });
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const debouncerQuantity = useDebounce(kontorQuantity.count, 500);
  const [openPlans, setOpenPlans] = useState(false);

  const { getUserRole, userRole } = useContext(LoginContext);

  useEffect(() => {
    offerProductListFunc({ id });
    offerHistoriesListFunc({ id });
    getOfferBasketFunc({
      id,
    });

    getUserRole();
  }, []);

  useEffect(() => {
    checkSpecialOfferDisabled();
  }, [offerProductsObj]);

  useEffect(() => {
    if (offerProducts != null) {
      if (offerProducts?.length !== 0 && offerProducts?.offer_id !== null) {
        partialPaymentInfoFunc({ offer_id: offerProducts?.offer_id });
      }

      setOfferProductsObj(offerProducts);
    }
  }, [offerProducts]);

  useEffect(() => {
    if (offerHistoryDetail !== null) {
      const newOfferObj = offerHistoryDetail?.product_offers?.concat(
        offerHistoryDetail?.module_offers
      );
      setOfferHistoryDetailObj(newOfferObj);
    }
  }, [offerHistoryDetail]);

  const checkSpecialOfferDisabled = () => {
    const shouldDisableOffer = offerProductsObj?.detail?.some(
      (row) => row.check_special_price === "Onayda"
    );

    if (shouldDisableOffer) {
      setDisableOffer(true);
    } else {
      setDisableOffer(false);
    }
  };

  const handleChangeKontor = (e, row, index) => {
    const selectecItem = {
      id: row.id,
      count: e.target.value,
    };
    setKontorQuantity(selectecItem);
    row.count = e.target.value;
    forceUpdate();
  };

  useEffect(() => {
    if (debouncerQuantity > 0) {
      offerBasketChangeFunc({
        id,
        detail_id: kontorQuantity.id,
        sendObj: {
          count: Number(debouncerQuantity),
        },
      });
    }
  }, [debouncerQuantity]);

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "98%",
            justifyContent: "space-around",
            overflow: "auto",
            marginTop: 20,
            alignItems: "center",
            padding: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "98%",
            }}
          >
            {offerProductList?.data?.map((row, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    minWidth: 300,
                    marginLeft: 20,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      backgroundColor: "#fff",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ position: "relative", width: "100%" }}>
                      <Box
                        sx={{
                          background: "#f96c2d",
                          borderTopLeftRadius: 4,
                          borderTopRightRadius: 4,
                          color: "#fff",
                          fontSize: "20px",
                          fontWeight: 500,
                          fontFamily: "segoeuiRg",
                          textAlign: "center",
                          width: "100%",
                          lineHeight: 1.5,
                        }}
                      >
                        {row?.description}
                      </Box>

                      <Box
                        sx={{
                          padding: "0px 20px",
                          height: "100%",
                          margin: 1,
                        }}
                      >
                        <Box
                          sx={{
                            fontSize: 20,
                            fontWeight: "bold",
                            fontFamily: "segoeuiB",
                            marginBottom: 1,
                          }}
                        >
                          {row.name}
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              fontSize: 18,
                              fontWeight: 500,
                              color: "rgba(36,35,58,0.5)",
                              textDecoration: "line-through",
                            }}
                          >
                            {row.price}₺
                          </Box>
                          <Box
                            sx={{
                              fontSize: 18,
                              fontWeight: 500,
                              border: "1px solid #f96c2d",
                              borderRadius: 1,
                              color: "#f96c2d",
                              padding: "10px 20px",
                              lineHeight: 1,
                            }}
                          >
                            %{row.discount_value}
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            color: "#24233a",
                            fontSize: 18,
                            fontWeight: 500,
                            marginBottom: 1,
                            marginTop: 1,
                            fontFamily: "segoeuiB",
                          }}
                        >
                          {row.payment_type} -{" "}
                          {row.price - (row.price * row.discount_value) / 100}₺
                        </Box>

                        <Box>
                          <Box
                            sx={{
                              fontSize: "24px",
                              fontWeight: "bold",
                              fontFamily: "segoeuiB",
                              color: "#24233a",
                            }}
                          >
                            {row.header_summary}
                          </Box>
                        </Box>
                        <Button
                          sx={{
                            width: "100%",
                            background: "#24233a",
                            "&:hover": {
                              background: "#f96c2d",
                            },
                            fontSize: 16,
                            borderRadius: 1,
                            color: "#fff",
                            lineHeight: 1,
                            fontFamily: "segoeuiB",
                          }}
                          target="_blank"
                          href={row?.link}
                        >
                          Ürünü Yakından İncele
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  <Button
                    onClick={() => {
                      offerBasketFunc({
                        id,
                        sendObj: {
                          id: row?.id,
                        },
                        offerProductListFunc,
                      });
                    }}
                    disabled={row?.in_the_basket || !row?.ready_for_sale}
                    style={{
                      backgroundColor: row?.in_the_basket
                        ? "gray"
                        : row?.ready_for_sale
                        ? "green"
                        : "red",
                      color: "#fff",
                      width: "40%",
                      marginTop: 10,
                      textTransform: "capitalize",
                      width: "80%",
                    }}
                  >
                    {row?.in_the_basket
                      ? "Sepette"
                      : row?.ready_for_sale
                      ? "Ekle"
                      : "Hazır Değil"}
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
        <div
          style={{
            marginTop: 40,
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div>
            <Paper
              onClick={() => {
                setSelectedCard("active");
              }}
              elevation={2}
              style={{
                width: 200,
                minHeight: 60,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                padding: 10,
                cursor: "pointer",
                backgroundColor:
                  selectedCard === "active" ? "#f96c2d" : "#f4f4f4",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                Aktif Teklif
              </div>
            </Paper>
            {offerHistoriesList?.map((row, ind) => {
              return (
                <Paper
                  onClick={() => {
                    setSelectedCard(`history_${ind}`);
                    offerHistoryDetailFunc({
                      id,
                      offer_id: row?.id,
                    });
                  }}
                  elevation={2}
                  style={{
                    width: 300,
                    minHeight: 60,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    padding: 10,
                    marginTop: 10,
                    cursor: "pointer",
                    backgroundColor:
                      selectedCard === `history_${ind}` ? "#f96c2d" : "#f4f4f4",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      {moment(row?.payed_at).format("DD-MM-YYYY")} /{" "}
                      {moment(row?.expires_at).format("DD-MM-YYYY")} (
                      {row?.trial_days} gün)
                    </div>
                    <div>₺ {row?.total_amount}</div>
                  </div>
                  <Divider style={{ height: 2 }} />
                  <div>
                    {row?.product_offers?.map((row1) => {
                      return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              width: 10,
                              height: 10,
                              borderRadius: 99,
                              backgroundColor: row?.payed ? "green" : "red",
                            }}
                          />
                          <span style={{ marginLeft: 8 }}>
                            {row1?.product?.name} ({row1?.count}x)
                          </span>
                        </div>
                      );
                    })}
                    {row?.module_offers?.map((row1) => {
                      return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              width: 10,
                              height: 10,
                              borderRadius: 99,
                              backgroundColor: row?.payed ? "green" : "red",
                            }}
                          />
                          <span style={{ marginLeft: 8 }}>
                            {row1?.module?.name} ({row1?.count}x)
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </Paper>
              );
            })}
          </div>
          {selectedCard === "active" ? (
            offerProductsObj != null &&
            offerProductsObj?.length != 0 &&
            offerProductsObj?.detail?.length != 0 ? (
              <Paper
                elevation={2}
                style={{
                  width: "80%",
                  backgroundColor: "#fff",
                  minHeight: 400,
                  marginBottom: 180,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    margin: 10,
                  }}
                >
                  <div
                    style={{
                      flex: 2,
                      textAlign: "center",
                    }}
                  >
                    -
                  </div>
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      textDecoration: "underline",
                      fontFamily: "segoeuiB",
                    }}
                  >
                    Fiyat
                  </div>
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      textDecoration: "underline",
                      fontFamily: "segoeuiB",
                    }}
                  >
                    İndirimli Fiyat
                  </div>
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      textDecoration: "underline",
                      fontFamily: "segoeuiB",
                    }}
                  >
                    Adet
                  </div>
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      textDecoration: "underline",
                      fontFamily: "segoeuiB",
                    }}
                  >
                    Kırılım İndirimi
                  </div>

                  {/* <div
                    style={{
                      flex: 1.5,
                      textAlign: "center",
                      textDecoration: "underline",
                      fontFamily: "segoeuiB",
                    }}
                  >
                    Özel Fiyat
                  </div> */}

                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      textDecoration: "underline",
                      fontFamily: "segoeuiB",
                    }}
                  >
                    Aksiyon
                  </div>
                </div>

                <Paper
                  elevation={3}
                  style={{
                    width: "max-content",
                    minWidth: 200,
                    display: "flex",
                    justifyContent: "flex-end",
                    backgroundColor: "#f53b62",
                    margin: 10,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      marginRight: 15,
                      fontWeight: "bold",
                      color: "#fff",
                    }}
                  >
                    Ürün
                  </span>
                </Paper>
                {offerProductsObj?.detail?.map((row, index) => {
                  if (row?.module != null) return;
                  return (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          borderRadius: 4,
                          height: 28,
                          alignItems: "center",
                          margin: 10,
                        }}
                      >
                        <div style={{ flex: 2, textAlign: "center" }}>
                          {row?.product?.name}
                        </div>
                        <Divider
                          orientation="vertical"
                          sx={{
                            backgroundColor: "#f4f4f4",
                            width: "1px",
                            height: 20,
                          }}
                        />
                        <div style={{ flex: 1, textAlign: "center" }}>
                          {row?.base_price}
                        </div>
                        <Divider
                          orientation="vertical"
                          sx={{
                            backgroundColor: "#f4f4f4",
                            width: "1px",
                            height: 20,
                          }}
                        />
                        <div style={{ flex: 1, textAlign: "center" }}>
                          {row?.discount_price}
                        </div>
                        <Divider
                          orientation="vertical"
                          sx={{
                            backgroundColor: "#f4f4f4",
                            width: "1px",
                            height: 20,
                          }}
                        />
                        <div
                          style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {row.product.product_type === "kontor" ? (
                            <InputBase
                              variant="filled"
                              type="text"
                              style={{
                                width: 100,
                                color: "black",
                                fontSize: 15,
                                fontWeight: "400",
                                fontFamily: "segoeuiRg",
                                height: 35,
                                backgroundColor: "#f4f4f4",
                                paddingLeft: 10,
                                borderRadius: 4,
                              }}
                              value={row?.count}
                              onChange={(e) => {
                                handleChangeKontor(e, row, index);
                              }}
                            />
                          ) : (
                            <Select
                              styles={selectDefaultStyles}
                              options={offerCount}
                              value={{
                                value: row?.count,
                                label: row?.count,
                              }}
                              isSearchable={false}
                              hoverColor={"black"}
                              selectWidth={100}
                              menuColor={"#f4f4f4"}
                              color={"#000"}
                              placeholder={""}
                              onChange={(e) => {
                                offerBasketChangeFunc({
                                  id,
                                  detail_id: row?.id,
                                  sendObj: {
                                    count: e.value,
                                  },
                                });
                              }}
                              getOptionValue={(option) => option.value}
                              getOptionLabel={(option) => option.label}
                            />
                          )}
                        </div>
                        <Divider
                          orientation="vertical"
                          sx={{
                            backgroundColor: "#f4f4f4",
                            width: "1px",
                            height: 20,
                          }}
                        />
                        <div style={{ flex: 1, textAlign: "center" }}>
                          {row?.operation_price}
                        </div>
                        <Divider
                          orientation="vertical"
                          sx={{
                            backgroundColor: "#f4f4f4",
                            width: "1px",
                            height: 20,
                          }}
                        />
                        {userRole !== "setup" && (
                          <>
                            {/* <Divider
                              orientation="vertical"
                              sx={{
                                backgroundColor: "#f4f4f4",
                                width: "1px",
                                height: 20,
                              }}
                            />
                            <div
                              style={{
                                flex: 1.5,
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                              }}
                            >
                              {row?.special_price > 0 && (
                                <Tooltip title={"%" + row?.discount_manager}>
                                  <InputBase
                                    disabled
                                    variant="filled"
                                    type="text"
                                    style={{
                                      width: 100,
                                      color: "black",
                                      fontSize: 15,
                                      fontWeight: "400",
                                      fontFamily: "segoeuiRg",
                                      height: 35,
                                      backgroundColor: "#f4f4f4",
                                      paddingLeft: 10,
                                      borderRadius: 4,
                                    }}
                                    value={row?.special_price}
                                  />
                                </Tooltip>
                              )}

                              <Tooltip title="Özel Fiyat İste">
                                <SendIcon
                                  style={{
                                    color: "#f53b62",
                                    cursor: "pointer",
                                    marginLeft: -10,
                                  }}
                                  onClick={() => handleRequestSpeialPrice(row)}
                                />
                              </Tooltip>

                              {row?.check_special_price == "Özel Fiyat Yok" ? (
                                <HorizontalRule />
                              ) : row?.check_special_price === "Onayda" ? (
                                <Pending />
                              ) : row?.check_special_price === "Onaylandı" ? (
                                <CheckBox color="success" />
                              ) : row?.check_special_price === "Onaylanmadı" ? (
                                <Close color="error" />
                              ) : (
                                "x"
                              )}
                            </div> */}
                            <Divider
                              orientation="vertical"
                              sx={{
                                backgroundColor: "#f4f4f4",
                                width: "1px",
                                height: 20,
                              }}
                            />
                          </>
                        )}
                        <div style={{ flex: 1, textAlign: "center" }}>
                          <Checkbox
                            checked={row?.is_selected}
                            onChange={(e) => {
                              offerBasketChangeFunc({
                                id,
                                detail_id: row?.id,
                                sendObj: {
                                  is_selected: !row?.is_selected,
                                },
                              });
                            }}
                            sx={{
                              color: pink[800],
                              "&.Mui-checked": {
                                color: pink[600],
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
                <Paper
                  elevation={3}
                  style={{
                    width: "max-content",
                    minWidth: 200,
                    display: "flex",
                    justifyContent: "flex-end",
                    backgroundColor: "#f53b62",
                    margin: 10,
                  }}
                >
                  <span
                    style={{
                      fontSize: 16,
                      marginRight: 15,
                      fontWeight: "bold",
                      color: "#fff",
                    }}
                  >
                    Yanında Satabilirsin
                  </span>
                </Paper>
                {offerProductsObj?.detail?.map((row, index) => {
                  if (row?.module == null) return;
                  return (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          borderRadius: 4,
                          height: 28,
                          alignItems: "center",
                          margin: 10,
                        }}
                      >
                        <div
                          style={{
                            flex: 2,
                            textAlign: "center",
                            color: row?.product?.ready_for_sale
                              ? "#000"
                              : "red",
                          }}
                        >
                          {row?.module?.name}
                        </div>
                        <Divider
                          orientation="vertical"
                          sx={{
                            backgroundColor: "#f4f4f4",
                            width: "1px",
                            height: 20,
                          }}
                        />
                        <div style={{ flex: 1, textAlign: "center" }}>
                          {row?.base_price}
                        </div>
                        <Divider
                          orientation="vertical"
                          sx={{
                            backgroundColor: "#f4f4f4",
                            width: "1px",
                            height: 20,
                          }}
                        />
                        <div style={{ flex: 1, textAlign: "center" }}>
                          {row?.discount_price}
                        </div>
                        <Divider
                          orientation="vertical"
                          sx={{
                            backgroundColor: "#f4f4f4",
                            width: "1px",
                            height: 20,
                          }}
                        />
                        <div
                          style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Select
                            styles={selectDefaultStyles}
                            options={offerCount}
                            isDisabled={!row?.product?.ready_for_sale}
                            value={{
                              value: row?.count,
                              label: row?.count,
                            }}
                            isSearchable={false}
                            hoverColor={"black"}
                            selectWidth={100}
                            menuColor={"#f4f4f4"}
                            color={
                              row?.product?.ready_for_sale ? "#000" : "red"
                            }
                            placeholder={""}
                            onChange={(e) => {
                              offerBasketChangeFunc({
                                id,
                                detail_id: row?.id,
                                sendObj: {
                                  count: e.value,
                                },
                              });
                            }}
                            getOptionValue={(option) => option.value}
                            getOptionLabel={(option) => option.label}
                          />
                        </div>
                        <Divider
                          orientation="vertical"
                          sx={{
                            backgroundColor: "#f4f4f4",
                            width: "1px",
                            height: 20,
                          }}
                        />
                        <div style={{ flex: 1, textAlign: "center" }}>
                          {row?.operation_price}
                        </div>
                        <Divider
                          orientation="vertical"
                          sx={{
                            backgroundColor: "#f4f4f4",
                            width: "1px",
                            height: 20,
                          }}
                        />
                        {userRole !== "setup" && (
                          <>
                            {/* <Divider
                              orientation="vertical"
                              sx={{
                                backgroundColor: "#f4f4f4",
                                width: "1px",
                                height: 20,
                              }}
                            /> */}
                            {/* <div
                              style={{
                                flex: 1.5,
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                              }}
                            >
                              {row?.is_selected ? (
                                <>
                                  {row?.special_price > 0 && (
                                    <Tooltip
                                      title={"%" + row?.discount_manager}
                                    >
                                      <InputBase
                                        disabled
                                        variant="filled"
                                        type="text"
                                        style={{
                                          width: 100,
                                          color: "black",
                                          fontSize: 15,
                                          fontWeight: "400",
                                          fontFamily: "segoeuiRg",
                                          height: 35,
                                          backgroundColor: "#f4f4f4",
                                          paddingLeft: 10,
                                          borderRadius: 4,
                                        }}
                                        value={row?.special_price}
                                      />
                                    </Tooltip>
                                  )}

                                  <Tooltip title="Özel Fiyat İste">
                                    <SendIcon
                                      style={{
                                        color: "#f53b62",
                                        cursor: "pointer",
                                        marginLeft: -10,
                                      }}
                                      onClick={() =>
                                        handleRequestSpeialPrice(row)
                                      }
                                    />
                                  </Tooltip>

                                  {row?.check_special_price ==
                                  "Özel Fiyat Yok" ? (
                                    <HorizontalRule />
                                  ) : row?.check_special_price === "Onayda" ? (
                                    <Pending />
                                  ) : row?.check_special_price ===
                                    "Onaylandı" ? (
                                    <CheckBox color="success" />
                                  ) : row?.check_special_price ===
                                    "Onaylanmadı" ? (
                                    <Close color="error" />
                                  ) : (
                                    "x"
                                  )}
                                </>
                              ) : (
                                "-"
                              )}
                            </div>

                            <Divider
                              orientation="vertical"
                              sx={{
                                backgroundColor: "#f4f4f4",
                                width: "1px",
                                height: 20,
                              }}
                            /> */}
                          </>
                        )}
                        <div style={{ flex: 1, textAlign: "center" }}>
                          {row?.product?.ready_for_sale ? (
                            <Checkbox
                              checked={row?.is_selected}
                              onChange={(e) => {
                                offerBasketChangeFunc({
                                  id,
                                  detail_id: row?.id,
                                  sendObj: {
                                    is_selected: !row?.is_selected,
                                  },
                                });
                              }}
                              sx={{
                                color: pink[800],
                                "&.Mui-checked": {
                                  color: pink[600],
                                },
                              }}
                            />
                          ) : (
                            <div style={{ fontSize: 12, color: "red" }}>
                              Satışa hazır değil
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div
                  style={{
                    margin: 30,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    style={{
                      width: "auto",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      backgroundColor: "#f4f4f4",
                      padding: 5,
                      borderRadius: 4,
                    }}
                  >
                    <div>Toplam Fiyat</div>

                    <div>₺ {offerProductsObj?.total.toFixed(2)}</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    width: "70%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      borderRadius: 4,
                      flexDirection: "column",
                      alignSelf: "center",
                      justifyContent: "center",
                      backgroundColor: "#fff",
                      padding: "12px",
                      width: "90%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h4>Ön Ödeme</h4>
                      {offerProductsObj?.active_offer && (
                        <Button
                          style={{ height: 30 }}
                          color="success"
                          variant="contained"
                          size="small"
                          onClick={() => {
                            setPayedModal(true);
                          }}
                        >
                          Eft ile ödeme
                        </Button>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#f4f4f4",
                        borderRadius: 6,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {offerProductsObj.active_offer ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            fontSize: 14,
                          }}
                        >
                          <div style={{ marginBottom: 10 }}>
                            Yeni Ürün Ön Ödeme : ₺{" "}
                            {offerProductsObj?.eft_detail.new_eft_amount}
                          </div>
                          <div style={{ marginBottom: 10 }}>
                            Yenileme Ürün Ön Ödeme : ₺{" "}
                            {offerProductsObj?.eft_detail.renew_eft_amount}
                          </div>
                          <div style={{ marginBottom: 10 }}>
                            Yeni Ürün Kalan Ödeme : ₺{" "}
                            {offerProductsObj?.eft_detail.new_debt}
                          </div>
                          <div>
                            Yenileme Kalan Ödeme : ₺{" "}
                            {offerProductsObj?.eft_detail.renew_debt}
                          </div>
                        </div>
                      ) : (
                        <span style={{ color: "red" }}>
                          Teklif Yok / Eft Yapılamaz
                        </span>
                      )}
                    </div>
                    <div>
                      <Paid
                        style={{ cursor: "pointer", marginTop: 5 }}
                        onClick={() => {
                          window.open(
                            `https://sepettakip.com/offers/0/${offerProductsObj?.code}/q`,
                            "_blank"
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      borderRadius: 4,
                      flexDirection: "column",
                      alignSelf: "center",
                      justifyContent: "center",
                      backgroundColor: "#fff",
                      padding: 12,
                      width: "90%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#f4f4f4",
                        borderRadius: 6,
                        alignItems: "center",
                        height: 150,
                        flexDirection: "column",
                        padding: 5,
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "15%",
                          display: "flex",
                          alignItems: "center",
                          fontFamily: "segoeuiBd",
                          fontSize: 22,
                          fontVariant: "all-petite-caps",
                          letterSpacing: 1.5,
                        }}
                      >
                        Parçalı Online Ödeme
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          height: "65%",
                        }}
                      >
                        <div
                          style={{
                            width: partialPaymentInfo?.partial_payment
                              ? "100%"
                              : "70%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                          }}
                        >
                          {/* <div>
                            Durum :{" "}
                            {partialPaymentInfo?.partial_payment ? (
                              <span style={{ color: "green" }}>
                                Aktif /{" "}
                                <span style={{ color: "black" }}>
                                  Tutar : ₺ {partialPaymentInfo?.amount}{" "}
                                </span>
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>Pasif</span>
                            )}
                          </div> */}
                          {partialPaymentInfo?.partial_payment && (
                            <Paper
                              style={{
                                display: "flex",
                                color: "#fff",
                                padding: 2,
                                backgroundColor: "#737373",
                                width: "50%",
                                marginTop: 10,
                                fontFamily: "segoeuiRg",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>Ön Ödeme</div>
                              <Divider
                                orientation="horizontal"
                                sx={{
                                  backgroundColor: "#fff",
                                  width: "2px",
                                  height: "100%",
                                }}
                              />
                              <div style={{ marginLeft: 5 }}>
                                {" "}
                                ₺ {partialPaymentInfo?.amount}{" "}
                              </div>
                            </Paper>
                          )}
                          {partialPaymentInfo?.partial_payment ? (
                            <>
                              <Button
                                style={{
                                  backgroundColor: "#f53b62",
                                  color: "#fff",
                                  fontFamily: "segoeuiB",
                                  width: "50%",
                                  display: "flex",
                                  justifyContent: "center",
                                  height: 20,
                                }}
                                size="small"
                                onClick={() => {
                                  let data = {
                                    offer_id: offerProductsObj?.offer_id,
                                  };
                                  partialPaymentDeleteFunc(data);
                                  setPartialPayment("");
                                }}
                                variant="contained"
                              >
                                Ön ödemeyi sıfırla
                              </Button>
                              <div></div>
                            </>
                          ) : (
                            <InputBase
                              multiline
                              spellCheck={false}
                              style={{
                                width: "100%",
                                fontFamily: "segoeuiRg",
                                paddingLeft: 8,
                                fontSize: 14,
                                backgroundColor: "#fff",
                                width: 150,
                                marginTop: 3,
                              }}
                              rows={1}
                              placeholder={"Ödeme Tutarı"}
                              className={classes.input}
                              value={partialPayment}
                              onChange={(e) => {
                                setPartialPayment(e.target.value);
                              }}
                            />
                          )}
                        </div>
                        {!partialPaymentInfo?.partial_payment && (
                          <div style={{ width: "30%" }}>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      selectedPaymentType === false
                                        ? true
                                        : false
                                    }
                                    color="success"
                                  />
                                }
                                label="Yeni"
                                onChange={(e) => {
                                  setselectedPaymentType(false);
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      selectedPaymentType === true
                                        ? true
                                        : false
                                    }
                                    color="success"
                                  />
                                }
                                label="Yenileme"
                                onChange={(e) => {
                                  setselectedPaymentType(true);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      {!partialPaymentInfo?.partial_payment && (
                        <div
                          style={{
                            height: "20%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            style={{
                              backgroundColor: "#f53b62",
                              color: "#fff",
                              fontFamily: "segoeuiB",
                              width: "33%",
                              display: "flex",
                              justifyContent: "center",
                              height: 20,
                            }}
                            size="small"
                            onClick={() => {
                              if (selectedPaymentType === null) {
                                alert("Teklif tipi seçiniz", 4500);
                              } else {
                                let data = {
                                  offer_id: offerProductsObj?.offer_id,
                                  sendObj: {
                                    amount: partialPayment,
                                    renew: selectedPaymentType,
                                  },
                                };
                                partialPaymentCreateFunc(data);
                                setselectedPaymentType(null);
                              }
                            }}
                            variant="contained"
                          >
                            Onayla
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* 
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {userInfo.department === "Operasyon Yonetimi" && (
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        Bedava Gün
                        <Select
                          styles={selectDefaultStyles}
                          options={freeDays}
                          value={{
                            value: selectedFreeDay,
                            label: selectedFreeDay,
                          }}
                          isSearchable={false}
                          hoverColor={"black"}
                          selectWidth={100}
                          menuColor={"#f4f4f4"}
                          color={"#000"}
                          placeholder={""}
                          onChange={(e) => {
                            let data = {
                              free_days: e.value,
                            };
                            changeOfferTime(id, data);
                            setSelectedFreeDay(e.value);
                          }}
                          getOptionValue={(option) => option.value}
                          getOptionLabel={(option) => option.label}
                        />
                      </div>
                    )}

                    {userInfo.department === "Operasyon Yonetimi" && (
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        Uzatmak için gün
                        <Select
                          styles={selectDefaultStyles}
                          options={data}
                          value={{
                            value: selectedExtendedDay,
                            label: selectedExtendedDay,
                          }}
                          isSearchable={false}
                          hoverColor={"black"}
                          selectWidth={100}
                          menuColor={"#f4f4f4"}
                          color={"#000"}
                          placeholder={""}
                          onChange={(e) => {
                            let data = {
                              extended_free_days: e.value,
                            };
                            changeOfferTime(id, data);
                            setSelectedExtendedDay(e.value);
                          }}
                          getOptionValue={(option) => option.value}
                          getOptionLabel={(option) => option.label}
                        />
                      </div>
                    )}
                  </div> */}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Button
                    onClick={() => {
                      setOfferPriceChangeModal(true);
                      setFinanceOfferRow({
                        restofficial: {
                          user_id: id,
                        },
                      });
                    }}
                    disabled={!offerProductsObj?.seen}
                    variant="contained"
                    size="medium"
                    style={{
                      backgroundColor: offerProductsObj?.seen
                        ? "green"
                        : "gray",
                      margin: 10,
                    }}
                  >
                    Fiyat Değiştir
                  </Button>
                  {/* {userRole === "collector" && ( */}
                  <RemoveRedEye
                    style={{
                      marginBottom: 10,
                      marginRight: 10,
                      width: 30,
                      height: 30,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOfferModal(true);
                    }}
                  />

                  <Button
                    style={{
                      color: "white",
                      backgroundColor: "#f53b62",
                      padding: "5px 10px 5px 10px",
                      margin: 10,
                      textTransform: "capitalize",
                      fontFamily: "segoeuiRg",
                    }}
                    onClick={() => setOpenPlans(true)}
                  >
                    Planlar
                  </Button>

                  <Button
                    onClick={() => {
                      let data = {
                        trigger_popup: !offerProductsObj?.trigger_popup,
                      };
                      sendOfferPopupFunc(id, data, "offer", "");
                    }}
                    style={{
                      color: "white",
                      backgroundColor: "#f53b62",
                      padding: "5px 10px 5px 10px",
                      margin: 10,
                      textTransform: "capitalize",
                      fontFamily: "segoeuiRg",
                    }}
                  >
                    Teklif Göster
                  </Button>
                  {/* )} */}
                  <Button
                    disabled={disableOffer}
                    onClick={() => {
                      createOfferFunc({
                        id,
                        sendObj: {
                          basket_id: offerProductsObj.id,
                        },
                      });
                    }}
                    style={{
                      color: "white",
                      backgroundColor: !disableOffer ? "#f53b62" : "#b9b9b9",
                      padding: "5px 10px 5px 10px",
                      margin: 10,
                      textTransform: "capitalize",
                      fontFamily: "segoeuiRg",
                    }}
                  >
                    Teklif Oluştur
                  </Button>
                </div>
              </Paper>
            ) : (
              <Paper
                elevation={2}
                style={{
                  width: "75%",
                  backgroundColor: "#fff",
                  minHeight: 400,
                  marginBottom: 180,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 32,
                  fontFamily: "math",
                }}
              >
                Aktif Sepeti Bulunmuyor.
              </Paper>
            )
          ) : (
            <Paper
              elevation={2}
              style={{
                width: "75%",
                backgroundColor: "#fff",
                minHeight: 400,
                marginBottom: 180,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "90%", height: "90%" }}>
                {offerHistoryDetailObj?.map((item, index) => {
                  return (
                    <>
                      <div
                        style={{
                          width: "100%",
                          marginTop: 20,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 18,
                            fontFamily: "segoeuiB",
                            color: "#293c51",
                          }}
                        >
                          {item?.product?.name || item?.module?.name} (
                          {item?.count}x)
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <span
                            style={{
                              textDecoration: "line-through",
                              textDecorationColor: "red",
                              fontFamily: "segoeuiB",
                              fontSize: 18,
                              color: "#293c51",
                            }}
                          >
                            {item?.base_amount}
                          </span>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <ArrowRightAltOutlined />
                          </div>
                          <span
                            style={{
                              fontFamily: "segoeuiB",
                              fontSize: 18,
                              color: "#293c51",
                            }}
                          >
                            {item?.amount}
                          </span>
                        </div>
                      </div>
                    </>
                  );
                })}
                <div
                  style={{
                    fontSize: 18,
                    fontFamily: "segoeuiRg",
                    color: "#293c51",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    color: "red",
                    marginTop: 15,
                  }}
                >
                  Toplam fiyat(KDV Dahil):
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      style={{
                        textDecoration: "line-through",
                        textDecorationColor: "black",
                        fontFamily: "segoeuiB",
                      }}
                    >
                      {offerHistoryDetail?.total_base_amount}
                    </span>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ArrowRightAltOutlined />
                    </div>
                    <span
                      style={{
                        fontFamily: "segoeuiB",
                        textDecoration: "underline",
                      }}
                    >
                      {offerHistoryDetail?.total_amount}
                    </span>
                  </div>
                </div>
              </div>
            </Paper>
          )}
        </div>
      </div>
      <PayedModal
        showPayment={true}
        payedModal={payedModal}
        setPayedModal={setPayedModal}
        r_id={id}
      />
      <OfferModalV2
        offerModal={offerModal}
        setOfferModal={setOfferModal}
        offerCode={offerProductsObj?.code}
      />

      <OfferPriceChangeModal
        offerPriceChangeModal={offerPriceChangeModal}
        setOfferPriceChangeModal={setOfferPriceChangeModal}
        financeOfferRow={financeOfferRow}
        setFinanceOfferRow={setFinanceOfferRow}
        restId={id}
        offerProductsObj={offerProductsObj}
      />

      {openPlans && (
        <PlanModal
          openPlans={openPlans}
          setOpenPlans={setOpenPlans}
          offerId={id}
          basketId={offerProductsObj.id}
        />
      )}
    </>
  );
};
