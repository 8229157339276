import React, { useContext, useEffect, useReducer } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { RestaurantContext } from "../../../../contexts/restaurantContext";
import { orderProductsImage } from "../../../../components/converter/orderProductsImage";
import sepettakipLogo from "../../../../utils/assets/sepettakipLogos/sepettakip-logo.webp";
import sepetappLogo from "../../../../utils/assets/sepettakipLogos/sepetapp-logo.webp";
import sepetfastLogo from "../../../../utils/assets/sepettakipLogos/sepetfast-logo.webp";
import calleridLogo from "../../../../utils/assets/sepettakipLogos/callerid-logo.webp";
import courierLogo from "../../../../utils/assets/sepettakipLogos/courier-logo.webp";
import stoktakipLogo from "../../../../utils/assets/sepettakipLogos/stoktakip-logo.webp";
import masatakipLogo from "../../../../utils/assets/sepettakipLogos/masatakip-logo.webp";
import coklutabelaLogo from "../../../../utils/assets/sepettakipLogos/cokluTabela-logo.png";
import callerPlusLogo from "../../../../utils/assets/sepettakipLogos/callerplus-logo.webp";
import emptyPhoto from "../../../../utils/assets/emptyPhoto.jpg";
import offerBg from "../../../../utils/assets/offer-bg.png";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import restaurantService from "../../../../services/restaurantService";
import { useToast } from "../../../../components/snackbar";
import { formatCurrency } from "../../../../components/currency";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

export default function PlanModal({
  openPlans,
  setOpenPlans,
  offerId,
  basketId,
}) {
  const { alert } = useToast();
  const classes = useStyles();
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const { changeOfferBasketPlan, getOfferBasketPlan, offerBasketPlan } =
    useContext(RestaurantContext);

  useEffect(() => {
    if (offerId) {
      getOfferBasketPlan(offerId);
    }
  }, [basketId, offerId]);

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={openPlans}
      className={classes.modal}
      onClose={() => {
        setOpenPlans(false);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          padding: 25,
          width: "100%",
          overflow: "auto",
          maxHeight: 650,
          height: "90%",
        }}
      >
        <Box>
          <Grid container spacing={2}>
            {offerBasketPlan?.map((plan) => (
              <Grid item xs={6} lg={4} xl={3}>
                <Card
                  key={plan.plan.id}
                  sx={{
                    mb: 2,
                    cursor: "pointer",
                    position: "relative",
                    border: plan.selected_plan
                      ? "2px solid #319a77"
                      : "0px solid #f2f3f9",
                    overflow: "initial",
                  }}
                  onClick={() => {
                    plan.selected_plan = !plan.selected_plan;
                    forceUpdate();
                  }}
                >
                  <div
                    style={{
                      background: "#f2f3f9",
                      borderRadius: 8,
                      padding: 12,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        zIndex: 1000,
                        position: "relative",
                        justifyContent: "space-between",
                        marginBottom: 50,
                      }}
                    >
                      <Typography
                        variant="h6"
                        style={{
                          color: "#319a77",
                          fontSize: 28,
                          fontWeight: 800,
                        }}
                      >
                        {plan.plan.name}
                      </Typography>

                      <div
                        style={{
                          position: "absolute",
                          right: -16,
                          top: -16,
                        }}
                      >
                        <img
                          src={offerBg}
                          style={{
                            maxWidth: 150,
                            width: "100%",
                            position: "relative",
                            top: "-10px",
                          }}
                        />

                        {plan.discount_rate > 0 && (
                          <p
                            style={{
                              color: "#fff",
                              textAlign: "center",
                              fontWeight: "bold",
                              top: "50%",
                              transform: "translateY(-50%)",
                              position: "absolute",
                              margin: 0,
                              right: "10%",
                            }}
                          >
                            Kısa Süreliğine <br />%{plan.discount_rate} İndirim
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <Typography
                    variant="body1"
                    style={{ fontWeight: "bold", margin: "10px 0px" }}
                  >
                    Teklif Detayları
                  </Typography>

                  <TableContainer
                    component={Paper}
                    sx={{ borderRadius: 2, boxShadow: 3 }}
                  >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow
                          style={{
                            background: "#c5cad1",
                            color: "#363a40",
                            borderRadius: 8,
                          }}
                        >
                          <TableCell
                            style={{
                              color: "#363a40",
                              fontWeight: "bold",
                              width: 175,
                            }}
                          >
                            Hizmet / Hizmetler
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#363a40",
                              fontWeight: "bold",
                              width: 50,
                            }}
                            align="right"
                          >
                            Adet
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#363a40",
                              fontWeight: "bold",
                            }}
                            align="right"
                          >
                            Toplam (₺)
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {plan.features.map((row) => (
                          <TableRow key={row.name}>
                            <TableCell
                              style={{
                                background: "#f2f3f9",
                                color: "#363a40",
                                borderRadius: 8,
                                fontSize: 12,
                              }}
                            >
                              {row.name}
                            </TableCell>
                            <TableCell
                              style={{
                                background: "#f2f3f9",
                                color: "#363a40",
                                borderRadius: 8,
                                fontSize: 12,
                              }}
                              align="right"
                            >
                              {row.count}
                            </TableCell>
                            <TableCell
                              style={{
                                background: "#f2f3f9",
                                color: "#363a40",
                                borderRadius: 8,
                                fontSize: 12,
                              }}
                              align="right"
                            >
                              {row.price}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <div
                    style={{
                      background: "#f2f3f9",
                      borderRadius: 8,
                      padding: 12,
                      marginTop: 10,
                    }}
                  >
                    {plan.discount_steps.map((step) => (
                      <div
                        style={{
                          fontSize: 12,
                          fontFamily: "MonBd",
                          margin: 0,
                          marginBottom: 10,
                          color: "#8c97a4",
                          display: "flex",
                          justifyContent: "space-between",
                          borderBottom: `0.5px solid "#cdced1"`,
                        }}
                      >
                        <div>{step.name}</div>
                        <div>{formatCurrency(step.price)}</div>
                      </div>
                    ))}
                    {plan?.basket_detail?.kdv?.rate > 0 && (
                      <div
                        style={{
                          fontSize: 12,
                          fontFamily: "MonBd",
                          margin: 0,
                          marginBottom: 10,
                          color: "#8c97a4",
                          display: "flex",
                          justifyContent: "space-between",
                          borderBottom: `0.5px solid "#cdced1"`,
                        }}
                      >
                        <div>Genel Toplam (KDV Hariç)</div>
                        <div>{formatCurrency(plan.total)}</div>
                      </div>
                    )}

                    <div
                      style={{
                        fontSize: 12,
                        fontFamily: "MonBd",
                        margin: 0,
                        marginBottom: 10,
                        color: "#8c97a4",
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: `0.5px solid "#cdced1"`,
                      }}
                    >
                      <div>KDV </div>
                      <div>%{plan.kdv.rate}</div>
                    </div>

                    <div
                      style={{
                        fontSize: 12,
                        fontFamily: "MonBd",
                        margin: 0,
                        color: "#8c97a4",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>Genel Toplam (KDV Dahil)</div>
                      <div>{formatCurrency(plan.total)}</div>
                    </div>
                  </div>
                </Card>
              </Grid>
            ))}
          </Grid>

          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
          >
            <Button
              variant="contained"
              fullWidth
              onClick={() => setOpenPlans(false)}
              style={{ marginRight: 20, background: "#a70000" }}
            >
              Vazgeç
            </Button>

            <Button
              variant="contained"
              style={{
                background: "tomato",
                color: "#fff",

                marginRight: 20,
              }}
              fullWidth
              onClick={async () => {
                try {
                  const selectedPlanIds = offerBasketPlan
                    .filter((plan) => plan.selected_plan)
                    .map((plan) => plan.plan.id);

                  await restaurantService.show_offer_plans(
                    offerId,
                    selectedPlanIds
                  );
                } catch (error) {
                  alert("Bir hata oluştu. Lütfen tekrar deneyin.");
                }
              }}
            >
              Seçili Planları Müşteriye Göster
            </Button>

            <Button
              variant="contained"
              style={{ background: "green", color: "#fff" }}
              fullWidth
              onClick={() => {
                const selectedPlan = offerBasketPlan.filter(
                  (item) => item.selected_plan
                );

                if (selectedPlan.length !== 1) {
                  alert("Lütfen yalnızca bir plan seçiniz.");
                  return;
                }

                const data = {
                  plan: selectedPlan[0].plan.id,
                };
                changeOfferBasketPlan(offerId, data, basketId);
                setOpenPlans(false);
              }}
            >
              Teklife Uygula
            </Button>
          </div>
        </Box>
      </Card>
    </Modal>
  );
}
