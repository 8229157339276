import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Card,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";

import { RestaurantContext } from "../../../contexts/restaurantContext";

import offerBg from "../../../utils/assets/offer-bg.png";
import html2pdf from "html2pdf.js";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { formatCurrency } from "../../currency";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const OfferModalV2 = ({ offerModal, setOfferModal, offerCode }) => {
  const classes = useStyles();
  const { offerInfoFunc, offerDetail } = useContext(RestaurantContext);
  const [offerDetailObj, setOfferDetailObj] = useState(null);
  const pdfContent = useRef();

  useEffect(() => {
    if (offerModal === true) {
      offerInfoFunc({
        offerCode,
      });
    }
  }, [offerModal]);

  useEffect(() => {
    if (offerDetail !== null) {
      const newOfferObj = offerDetail?.product_offers_new?.concat(
        offerDetail?.module_offers_new
      );
      setOfferDetailObj(newOfferObj);
    }
  }, [offerDetail]);

  const downloadPDF = () => {
    const element = pdfContent.current;
    html2pdf()
      .set({
        filename: "teklif.pdf",
        margin: [10, 20, 10, 20], // Üst, sağ, alt, sol margin
        html2canvas: { scale: 2, useCORS: true, scrollX: 0, scrollY: 0 },
      })
      .from(element)
      .save();
  };

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={offerModal}
      className={classes.modal}
      onClose={() => {
        setOfferModal(false);
      }}
    >
      <Card
        className={classes.paper}
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",
          height: "90%",
          backgroundColor: "#fff",
          padding: 25,
          maxHeight: 700,
          overflow: "auto",
        }}
      >
        <Tooltip title="Pdf olarak indir" placement="top">
          <PictureAsPdfIcon
            onClick={downloadPDF}
            style={{ color: "#319a77", marginBottom: 10, cursor: "pointer" }}
          />
        </Tooltip>
        <div ref={pdfContent}>
          <div
            style={{
              background: "#f2f3f9",
              borderRadius: 8,
              padding: 12,
            }}
          >
            <div
              style={{
                background: "#f2f3f9",
                borderRadius: 8,
                padding: 12,
                position: "relative",
              }}
            >
              <div style={{ width: "80%" }}>
                <p
                  style={{
                    fontFamily: "MonMd",
                    color: "#319a77",
                    margin: 0,
                    fontSize: 13,
                  }}
                >
                  Tarih:{" "}
                  <b>
                    {moment(offerDetail?.basket_detail?.created_at).format(
                      "DD-MM-YYYY"
                    )}
                  </b>
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    zIndex: 1000,
                    position: "relative",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      style={{
                        color: "#319a77",
                        fontSize: 24,
                        fontWeight: 800,
                        fontFamily: "MonExB",
                      }}
                    >
                      TEKLİF: {offerDetail?.package_product_plan?.name}
                    </Typography>

                    {offerDetail?.basket_detail?.free_months > 0 && (
                      <Typography
                        style={{
                          color: "#319a77",
                          fontFamily: "MonExB",
                          marginLeft: 10,
                          fontSize: 24,
                          fontVariant: "all-petite-caps",
                        }}
                      >
                        + {offerDetail?.basket_detail?.free_months} AY BEDAVA
                      </Typography>
                    )}
                  </div>
                </div>
                <Typography
                  variant="body1"
                  style={{
                    fontFamily: "MonBd",
                    margin: "10px 0px",
                    fontSize: 14,
                    color: "#8c97a4",
                  }}
                >
                  Müşteri Bilgileri
                </Typography>
                <div style={{ width: "60%" }}>
                  <div
                    style={{
                      fontFamily: "MonMd",
                      fontSize: 13,
                      color: "#8c97a4",
                      display: "flex",
                    }}
                  >
                    <div style={{ width: 90 }}>Firma Adı: </div>
                    <div>{offerDetail?.basket_detail?.restofficial?.name}</div>
                  </div>
                  <div
                    style={{
                      fontFamily: "MonMd",
                      fontSize: 13,
                      color: "#8c97a4",
                      display: "flex",
                    }}
                  >
                    <div style={{ width: 90 }}>Yetkili Kişi: </div>
                    <div style={{ display: "flex" }}>
                      {offerDetail?.basket_detail?.restofficial?.first_name}{" "}
                      {offerDetail?.basket_detail?.restofficial?.last_name}
                    </div>
                  </div>
                  <div
                    style={{
                      fontFamily: "MonMd",
                      fontSize: 13,
                      color: "#8c97a4",
                      display: "flex",
                    }}
                  >
                    <div style={{ width: 90 }}>Telefon: </div>
                    <div>
                      +{offerDetail?.basket_detail?.restofficial?.phone}
                    </div>
                  </div>
                  <div
                    style={{
                      fontFamily: "MonMd",
                      fontSize: 13,
                      color: "#8c97a4",
                      display: "flex",
                    }}
                  >
                    <div style={{ width: 90 }}>E-posta: </div>
                    <div>{offerDetail?.basket_detail?.restofficial?.email}</div>
                  </div>
                </div>

                <p
                  style={{
                    fontFamily: "MonMd",
                    fontSize: 13,
                    color: "#8c97a4",
                    marginTop: 10,
                  }}
                >
                  Sayın{" "}
                  <b>
                    {offerDetail?.restofficial?.first_name +
                      " " +
                      offerDetail?.restofficial?.last_name}
                  </b>
                  , <br />
                  Restoran işletmenizin dijitalleşme sürecini hızlandırmak ve
                  verimli bir şekilde yönetmenize yardımcı olmak amacıyla
                  geliştirdiğimiz SepetTakip çözümümüzü sunmaktan mutluluk
                  duyuyoruz.
                </p>

                <ul
                  style={{
                    fontFamily: "MonMd",
                    fontSize: 13,
                    color: "#8c97a4",
                    marginLeft: 20,
                  }}
                >
                  <li>
                    <b>Teslimat Süresi</b>: 1 iş günü içerisinde
                  </li>
                  <li>
                    <b>Garanti ve Destek</b>: Ücretsiz teknik destek ve
                    güncelleme
                  </li>
                </ul>
              </div>
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: -8,
                }}
              >
                <img
                  src={offerBg}
                  style={{
                    maxWidth: 200,
                    width: "100%",
                    position: "relative",
                    top: "-10px",
                  }}
                />

                <p
                  style={{
                    color: "#fff",
                    textAlign: "center",
                    fontWeight: "bold",
                    top: "40%",
                    transform: "translateY(-50%)",
                    fontFamily: "MonExB",
                    position: "absolute",
                    margin: 0,
                    right: "10%",
                    fontSize: 20,
                  }}
                >
                  {offerDetail?.basket_detail?.discount_rate > 0 && (
                    <div>
                      <div>Kısa Süreliğine</div>
                      <div>
                        %{offerDetail?.basket_detail?.discount_rate} İndirim
                      </div>
                    </div>
                  )}
                  {offerDetail?.basket_detail?.free_months > 0 &&
                    `+ ${offerDetail?.basket_detail?.free_months} Ay Bedava`}
                </p>
              </div>
            </div>
          </div>

          <Typography
            variant="body1"
            style={{
              fontFamily: "segoeuiRg",
              margin: "10px 0px",
              color: "#8c97a4",
              fontWeight: "bold",
            }}
          >
            Teklif Detayları
          </Typography>

          <TableContainer
            sx={{
              borderRadius: 2,
              overflow: "initial",
              background: "#f2f3f9",
            }}
          >
            <Table
              sx={{
                ".MuiTableCell-root": {
                  height: 3,
                  lineHeight: 2,
                },
                ".MuiTableCell-root td": {
                  color: "#fff",
                },
                overflow: "auto",
              }}
            >
              <TableHead>
                <TableRow
                  style={{
                    background: "#f2f3f9",
                    color: "#363a40",
                    borderRadius: 8,
                  }}
                >
                  <TableCell
                    style={{
                      color: "#363a40",
                      fontFamily: "MonBd",
                      width: 175,
                      fontSize: 12,
                      border: `2px solid #cdced1`,
                    }}
                  >
                    Hizmet / Hizmetler
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#363a40",
                      fontSize: 12,
                      width: "10%",
                      fontFamily: "MonBd",
                      border: `2px solid #cdced1`,
                    }}
                    align="center"
                  >
                    Adet
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#363a40",
                      fontSize: 12,
                      fontFamily: "MonBd",
                      border: `2px solid #cdced1`,
                    }}
                    align="right"
                  >
                    Toplam
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {offerDetail?.basket_detail?.features.map((row, i) => (
                  <TableRow key={row.id}>
                    <TableCell
                      style={{
                        background: "#f2f3f9",
                        color: "#556474",
                        borderRadius: 8,
                        fontSize: 12,
                        borderBottom: `2px solid #cdced1`,
                        borderRight: `2px solid #cdced1`,
                        borderLeft: `2px solid #cdced1`,
                        fontFamily: "MonMd",
                        padding: "4px 8px",
                        height: 10,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {row.name}
                      </div>
                    </TableCell>
                    <TableCell
                      style={{
                        background: "#f2f3f9",
                        color: "#556474",
                        borderRadius: 8,
                        fontSize: 12,
                        borderBottom: `2px solid #cdced1`,
                        borderRight: `2px solid #cdced1`,
                        fontFamily: "MonMd",
                      }}
                      align="center"
                    >
                      {row.count}
                    </TableCell>
                    <TableCell
                      style={{
                        background: "#f2f3f9",
                        color: "#556474",
                        borderRadius: 8,
                        fontSize: 12,
                        borderBottom: `2px solid #cdced1`,
                        borderRight: `2px solid #cdced1`,
                        fontFamily: "MonMd",
                      }}
                      align="right"
                    >
                      {row.price}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div
            style={{
              background: "#f2f3f9",
              borderRadius: 8,
              padding: 12,
              marginTop: 10,
            }}
          >
            {offerDetail?.basket_detail?.discount_steps?.map((step) => (
              <div
                style={{
                  fontSize: 14,
                  fontFamily: "MonBd",
                  margin: 0,
                  marginBottom: 10,
                  color: "#8c97a4",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>{step.name}</div>
                <div>{formatCurrency(step.price)}</div>
              </div>
            ))}
            <div
              style={{
                fontSize: 14,
                fontFamily: "MonBd",
                margin: 0,
                marginBottom: 10,
                color: "#8c97a4",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>Genel Toplam (KDV Hariç)</div>
              <div>
                {formatCurrency(offerDetail?.basket_detail?.total_amount)}
              </div>
            </div>

            <div
              style={{
                fontSize: 14,
                fontFamily: "MonBd",
                margin: 0,
                marginBottom: 10,
                color: "#8c97a4",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                KDV:{" "}
                <span style={{ fontSize: 8 }}>
                  {offerDetail?.basket_detail?.kdv?.txt}
                </span>
              </div>
              <div>%{offerDetail?.basket_detail?.kdv?.rate}</div>
            </div>

            <div
              style={{
                fontSize: 14,
                fontFamily: "MonBd",
                margin: 0,
                color: "#8c97a4",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>Genel Toplam (KDV Dahil)</div>
              <div>
                {formatCurrency(offerDetail?.basket_detail?.total_amount_kdv)}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Modal>
  );
};

export default OfferModalV2;
