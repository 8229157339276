import React, { useContext, useState } from "react";

import {
  Button,
  Card,
  Chip,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { RestaurantContext } from "../../../../contexts/restaurantContext";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Calendar from "./Calendar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useToast } from "../../../../components/snackbar";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
}));

export default function CalendarDetailModal({
  openCalendarDetail,
  setOpenCalendarDetail,
  calendarDetail,
  selectedCalendarDay,
  setSelectedCalendarDay,
  selectedMeeting,
  setSelectedMeeting,
}) {
  const { alert } = useToast();
  const classes = useStyles();
  const { changeCalendarDetail, appointmentTaskBackFunc, calendarDetailInfo } =
    useContext(RestaurantContext);
  const [selectedHour, setSelectedHour] = useState(null);

  const handleSelectDate = (row) => {
    setSelectedMeeting(row);
  };

  const handleSaveNewTime = () => {
    const value = moment(selectedHour).format("HH:mm");
    selectedCalendarDay = moment(selectedCalendarDay).format("YYYY-MM-DD");
    const dateFormat = `${selectedCalendarDay}T${value}:00`;

    if (!selectedHour) return alert("Lütfen bir saat seçin.");

    const data = {
      start_at: dateFormat,
    };
    const meetingDay = moment(selectedMeeting.start_at).format("YYYY-MM-DD");
    changeCalendarDetail(selectedMeeting.id, data, meetingDay);
    setSelectedMeeting(null);
  };

  const groupedTasks = calendarDetail.reduce((acc, item) => {
    const taskTypeName = item.task_type.name;
    acc[taskTypeName] = (acc[taskTypeName] || 0) + 1;
    return acc;
  }, {});

  const groupedActions = calendarDetail.reduce((acc, item) => {
    const actionName = item.title;
    acc[actionName] = (acc[actionName] || 0) + 1;
    return acc;
  }, {});

  const groupedPayment = calendarDetail.filter(
    (item) => item.title === "Ödeme Sözü"
  );

  let totalPrice = 0;
  let totalEftPrice = 0;

  groupedPayment.forEach((item) => {
    totalPrice += item.offer?.total_amount;
    totalEftPrice += item.offer?.eft_amount;
  });

  let totalCashPrice = totalPrice - totalEftPrice;

  return (
    <Modal
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      open={openCalendarDetail}
      className={classes.modal}
      onClose={() => {
        setOpenCalendarDetail(false);
        setSelectedMeeting(null);
        setSelectedCalendarDay(null);
      }}
    >
      <Card
        style={{
          width: "80%",
          padding: 25,
          height: "80%",
          overflow: "auto",
          maxHeight: "600px",
        }}
      >
        <Grid container spacing={2} style={{ marginBottom: 20 }}>
          <Grid item xs={4}>
            <Paper
              style={{
                height: "100%",
              }}
              elevation={2}
            >
              <h4
                style={{
                  margin: 0,
                  color: "#f53b62",
                  textAlign: "center",
                  marginBottom: 10,
                  fontSize: 20,
                  fontWeight: 600,
                }}
              >
                Task Liste
              </h4>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                {groupedTasks &&
                  Object.keys(groupedTasks).map((key, index) => {
                    return (
                      <Chip
                        key={index}
                        label={`${key} : ${groupedTasks[key]}`}
                        style={{
                          background: "#f53b62",
                          color: "#fff",
                          margin: 5,
                        }}
                      />
                    );
                  })}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper
              style={{
                height: "100%",
              }}
              elevation={2}
            >
              <h4
                style={{
                  margin: 0,
                  color: "#f53b62",
                  textAlign: "center",
                  marginBottom: 10,
                  fontSize: 20,
                  fontWeight: 600,
                }}
              >
                Aksiyon Liste
              </h4>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                {groupedActions &&
                  Object.keys(groupedActions).map((key, index) => {
                    return (
                      <Chip
                        key={index}
                        label={`${key} : ${groupedActions[key]}`}
                        style={{
                          background: "#f53b62",
                          color: "#fff",
                          margin: 5,
                        }}
                      />
                    );
                  })}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper
              style={{
                height: "100%",
              }}
              elevation={2}
            >
              <h4
                style={{
                  margin: 0,
                  color: "#f53b62",
                  textAlign: "center",
                  marginBottom: 10,
                  fontSize: 20,
                  fontWeight: 600,
                }}
              >
                Bugünün Ödeme Sözleri
              </h4>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: "5px 10px",
                    background: "#f53b62",
                    color: "#fff",
                    margin: 5,
                    width: "100%",
                    borderRadius: 16,
                    fontSize: 13,
                  }}
                >
                  <span>Toplam Tutar:</span>
                  <span>{totalPrice} TL</span>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: "5px 10px",
                    background: "#f53b62",
                    color: "#fff",
                    margin: 5,
                    width: "100%",
                    borderRadius: 16,
                    fontSize: 13,
                  }}
                >
                  <span>Alınan Ön Ödeme:</span>
                  <span>{totalEftPrice} TL</span>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: "5px 10px",
                    background: "#f53b62",
                    color: "#fff",
                    margin: 5,
                    width: "100%",
                    borderRadius: 16,
                    fontSize: 13,
                  }}
                >
                  <span>Kalan Tutar:</span>
                  <span>{totalCashPrice} TL</span>
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Task Adı</TableCell>
                <TableCell align="center">Id</TableCell>
                <TableCell align="center">Restoran Adı</TableCell>
                <TableCell align="center">Yetkili Adı</TableCell>
                <TableCell align="center">Randevu Tarihi</TableCell>
                <TableCell align="center">Aksiyon</TableCell>
                <TableCell align="center">Teklif</TableCell>
                <TableCell align="center">Ön Ödeme</TableCell>
                <TableCell align="center">Son Not</TableCell>
                <TableCell align="center">Randevuyu Öne Al</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {calendarDetail?.map((row, ind) => {
                return (
                  <TableRow key={ind}>
                    <TableCell align="center">{row.task_type?.name}</TableCell>
                    <TableCell align="center">{row.task}</TableCell>
                    <TableCell align="center">
                      {row.restofficial?.name}
                    </TableCell>
                    <TableCell align="center">
                      {row.restofficial?.first_name +
                        " " +
                        row.restofficial?.last_name}
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Tarih Değiştir">
                        <Button
                          style={{
                            background: "#f53b62",
                            cursor: "pointer",
                            color: "#fff",
                          }}
                          onClick={() => handleSelectDate(row)}
                        >
                          {moment(row.start_at).format("DD-MM-YYYY HH:mm")}
                        </Button>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">{row.title}</TableCell>
                    <TableCell align="center">
                      {row.offer?.total_amount && row.offer.total_amount + "TL"}
                    </TableCell>
                    <TableCell align="center">
                      {row.offer?.eft_amount && row.offer.eft_amount + "TL"}
                    </TableCell>
                    <TableCell align="center">{row.note}</TableCell>
                    <TableCell align="center">
                      <ArrowCircleRightIcon
                        style={{ color: "#f53b62", cursor: "pointer" }}
                        onClick={() => {
                          appointmentTaskBackFunc(
                            {
                              id: row.id,
                            },
                            "calendar"
                          );
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        {selectedMeeting && (
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              background: "#fff",
              padding: "20px",
              width: "30%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              boxShadow: "2px 2px 20px 10px rgba(0,0,0,0.2)",
              borderRadius: 16,
            }}
          >
            <h4
              style={{
                textAlign: "center",
                fontFamily: "segoeuiRg",
                color: "black",
              }}
            >
              Aktif Randevu Saati:{" "}
              {moment(selectedMeeting.start_at).format("DD-MM-YYYY HH:mm")}
            </h4>

            <div style={{ display: "flex", marginBottom: 10 }}>
              <Calendar
                type="new_date"
                selectedCalendarDay={selectedCalendarDay}
                setSelectedCalendarDay={setSelectedCalendarDay}
                calendarDetail={calendarDetail}
                selectedMeeting={selectedMeeting}
                setSelectedMeeting={setSelectedMeeting}
              />
              <div className="time-picker">
                <DatePicker
                  selected={selectedHour}
                  onChange={(time) => setSelectedHour(time)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Saat"
                  timeFormat="HH:mm"
                  dateFormat="HH:mm"
                  placeholderText="Saat seçin"
                  inline
                  excludeTimes={calendarDetailInfo.map((item) =>
                    moment(item.start_at).toDate()
                  )}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <Button
                style={{
                  background: "#f53b62",
                  cursor: "pointer",
                  color: "#fff",
                  width: "50%",
                  marginRight: 20,
                }}
                onClick={() => setSelectedMeeting(null)}
              >
                Kapat
              </Button>

              <Button
                style={{
                  background: "green",
                  cursor: "pointer",
                  color: "#fff",
                  width: "50%",
                }}
                onClick={handleSaveNewTime}
              >
                Kaydet
              </Button>
            </div>
          </div>
        )}
      </Card>
    </Modal>
  );
}
