import { Inviter, SessionState, UserAgent } from "sip.js";
import { userAgent } from "./auth";
import calling from "./calling";
import answered from "./answered";
import errorCall from "./error";
import verimorCall from "./verimorCall";

export let inviter = "";
export let X_UUID = null;
export let callkey1;

const makeCall = ({
  enqueueSnackbar,
  closeSnackbar,
  sendCallData,
  cdrOutboundFunc,
  cdrAnswerFunc,
  cdrHangupFunc,
  cdrMissedCountFunc,
}) => {
  const audioElement = document.getElementById("remoteAudio");
  const target = UserAgent.makeURI(
    `sip:${sendCallData.callee}@gofody.tt360.live:8443`
  );

  function preferG711Only(sdp) {
    const sdpLines = sdp.split("\r\n");
    let mLineIndex = -1;

    // m=audio satırını bul
    for (let i = 0; i < sdpLines.length; i++) {
      if (sdpLines[i].indexOf("m=audio") === 0) {
        mLineIndex = i;
        break;
      }
    }

    if (mLineIndex === -1) {
      return sdp;
    }

    // G.711a ve G.711u codec'lerinin payload type'larını bul
    let pcmaPayload;
    let pcmuPayload;
    const validPayloads = [];
    for (let i = 0; i < sdpLines.length; i++) {
      if (
        sdpLines[i].indexOf("a=rtpmap") === 0 &&
        sdpLines[i].indexOf("PCMA/8000") !== -1
      ) {
        pcmaPayload = sdpLines[i].split(" ")[0].split(":")[1];
        validPayloads.push(pcmaPayload);
      }
      if (
        sdpLines[i].indexOf("a=rtpmap") === 0 &&
        sdpLines[i].indexOf("PCMU/8000") !== -1
      ) {
        pcmuPayload = sdpLines[i].split(" ")[0].split(":")[1];
        validPayloads.push(pcmuPayload);
      }
    }

    if (!pcmaPayload && !pcmuPayload) {
      return sdp;
    }

    // G.711a ve G.711u codec'lerini m=audio satırının başına taşı ve diğer codec'leri kaldır
    const mLineElements = sdpLines[mLineIndex].split(" ");
    const newMLine = [mLineElements[0], mLineElements[1], mLineElements[2]];

    // Önce PCMA ve PCMU'yu ekle
    if (pcmaPayload) newMLine.push(pcmaPayload);
    if (pcmuPayload) newMLine.push(pcmuPayload);

    // Diğer codec'leri eklemeyin
    sdpLines[mLineIndex] = newMLine.join(" ");

    // Diğer codec tanımlarını SDP'den kaldır
    const filteredSDPLines = sdpLines.filter((line) => {
      if (line.indexOf("a=rtpmap") === 0) {
        const payload = line.split(" ")[0].split(":")[1];
        return validPayloads.includes(payload);
      }
      return true;
    });

    return filteredSDPLines.join("\r\n");
  }

  function playCallEndedRingTone() {
    const audio = new Audio(
      "https://static.sepettakip.com/call_end_ringtone_santral.mp3"
    );
    audio.play().catch((error) => {
      console.error("Failed to play ringback tone:", error);
    });
    return audio;
  }

  // Create a user agent client to establish a session
  inviter = new Inviter(userAgent, target, {
    earlyMedia: true,
    sessionDescriptionHandlerOptions: {
      constraints: {
        audio: true,
        video: false,
        earlyMedia: true,
      },
      modifySDP: (sdp) => {
        return preferG711Only(sdp); // Burada preferG711Only kullanılıyor
      },
    },
    earlyMediaSessionDescriptionHandlers: {
      constraints: {
        audio: true,
        video: false,
        earlyMedia: true,
      },
    },
  });

  // Setup session state change handler
  inviter.stateChange.addListener((newState) => {
    switch (newState) {
      case SessionState.Establishing:
        calling(
          enqueueSnackbar,
          closeSnackbar,
          sendCallData.callee,
          callkey1,
          cdrHangupFunc
        );
        break;
      case SessionState.Established:
        const sessionDescriptionHandler = inviter.sessionDescriptionHandler;
        const remoteStream = new MediaStream();
        sessionDescriptionHandler.peerConnection
          .getReceivers()
          .forEach((receiver) => {
            if (receiver.track) {
              remoteStream.addTrack(receiver.track);
            }
          });

        audioElement.srcObject = remoteStream;
        audioElement.play();
        console.log("established", "established");
        answered(
          enqueueSnackbar,
          closeSnackbar,
          sendCallData.callee,
          cdrHangupFunc,
          callkey1
        );
        break;
      case SessionState.Terminated:
        closeSnackbar(callkey1);
        if (X_UUID !== null) {
          let data = {
            x_uuid: X_UUID,
            who_hanged_up: "agent",
          };
          cdrMissedCountFunc();
          cdrHangupFunc(data);
          playCallEndedRingTone();
          X_UUID = null;
          console.log("terminated", "terminated");
        }
        // ended(enqueueSnackbar, closeSnackbar, sendCallData.callee, callkey);
        break;
    }
  });

  // Options including delegate to capture response messages
  const inviteOptions = {
    requestDelegate: {
      onProgress: (response) => {
        console.log("onProgress", response);
        const sessionDescriptionHandler = inviter.sessionDescriptionHandler;
        const remoteStream = new MediaStream();
        sessionDescriptionHandler.peerConnection
          .getReceivers()
          .forEach((receiver) => {
            if (receiver.track) {
              remoteStream.addTrack(receiver.track);
            }
          });

        audioElement.srcObject = remoteStream;
        audioElement.play().catch((error) => {
          console.error("Failed to play remote audio:", error);
        });

        X_UUID = response.message.headers["X-Uuid"][0].raw;
        sendCallData.x_uuid = response.message.headers["X-Uuid"][0].raw;
        cdrOutboundFunc(sendCallData);
      },
      onAccept: (response) => {
        console.log("onAccept", response);
        let data = {
          x_uuid: response.message.headers["X-Uuid"][0].raw,
        };
        cdrAnswerFunc(data);
      },
      onReject: (response) => {
        console.log("onReject", response);
        if (
          response?.message.statusCode !== 487 &&
          response?.message.statusCode !== 486 &&
          response?.message.statusCode !== 480 &&
          response?.message.statusCode !== 408
        ) {
          verimorCall({
            enqueueSnackbar,
            closeSnackbar,
            sendCallData,
            cdrOutboundFunc,
            cdrAnswerFunc,
            cdrHangupFunc,
            cdrMissedCountFunc,
            reasonPhrase: response?.message?.reasonPhrase,
            statusCode: response?.message?.statusCode,
          });
        } else {
          errorCall(
            enqueueSnackbar,
            closeSnackbar,
            response?.message?.reasonPhrase,
            response?.message?.statusCode
          );
        }
      },
    },
  };

  // Send initial INVITE request
  inviter.invite(inviteOptions);
};

export default makeCall;
