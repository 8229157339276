import { http } from "./axios";

function officeuser_costs() {
  return http.get(`/officeuser/users/cost`);
}

function officeuser_costs_change(data) {
  return http.patch(`/officeuser/users/cost/${data.id}`, data.costObj);
}

function paidable_prim(control) {
  return http.get(`/officeuser/payable/prim?payed=${control}`);
}

function paidable_prim_approve(userId) {
  return http.patch(`/officeuser/payable/prim/${userId}`, {
    payed: true,
  });
}

function manager_selected_reports(startDate, endDate, userId) {
  return http.get(
    `/officeuser/manager/finance/report?start=${startDate}&end=${endDate}&user=${
      userId > 0 ? userId : ""
    }`
  );
}

function get_manager_works(data) {
  let dateUrl;
  if (data.selectedDateType === "date") {
    dateUrl = `start_date=${data.startDate}&end_date=${data.endDate}`;
  } else if (data.selectedDateType === "setupDate") {
    dateUrl = `setup_start_date=${data.setupStartDate}&setup_end_date=${data.setupEndDate}`;
  } else {
    dateUrl = `start_date=${data.startDate}&end_date=${data.endDate}&setup_start_date=${data.setupStartDate}&setup_end_date=${data.setupEndDate}`;
  }

  return http.get(
    `/officeuser/manager/finance/application?page=${data.page}&user=${data.user_id}&status=${data.status}&type=${data.type}&&seller=${data.seller}&&${dateUrl}&&from_by=${data.from_by}&&setup_status=${data.setup_status}`
  );
}

function get_payment_tracker(data) {
  return http.get(
    `/officeuser/manager/finance/paymenttracker?page=${data.page}&user=${data.userId}&status=${data.status}`
  );
}

function get_graphs(year, userId) {
  if (userId) {
    return http.get(`/officeuser/manager/graphs?year=${year}&user=${userId}`);
  }
  return http.get(`/officeuser/manager/graphs?year=${year}`);
}

function get_application_types() {
  return http.get(`/officeuser/application/types`);
}

function get_services_reports(data) {
  return http.get(
    `/officeuser/manager/service/report?user=${data.userId}&start=${data.start}&end=${data.end}&page=${data.page}`
  );
}

function get_services_reports_rating(userId, page) {
  return http.get(`/officeuser/restofficial/rating/${userId}?page=${page}`);
}

function update_services_reports(data) {
  return http.post(`officeuser/restofficial/rating/${data.id}`, data.obj);
}

function get_restaurant_services(data) {
  return http.get(
    `/officeuser/manager/service/restaurant?start=${data.start}&end=${data.end}&platform=${data.platform}&payment_type=${data.paymentType}`
  );
}

function get_restaurant_services_graphs() {
  return http.get(`/officeuser/manager/restaurant/graphs`);
}

function permission_application_types() {
  return http.get(`/officeuser/application/permissions/types`);
}

function permission_application_people() {
  return http.get(`/officeuser/application/permissions`);
}

function patch_application_type(id, data) {
  return http.patch(`/officeuser/application/permissions/${id}`, data);
}

function campaign_sms_types() {
  return http.get(`/officeuser/campaign/sms/types`);
}

function blocked_reasons() {
  return http.get(`/officeuser/blocked/reasons`);
}

function getSms(type, reason) {
  return http.get(`/officeuser/send/sms?data_type=${type}&reasons=${reason}`);
}

function postSmsCampaign(data) {
  return http.post(`/officeuser/send/sms`, data);
}

function get_notification_type(type, error = "", product = "") {
  return http.get(
    `/officeuser/notification?notif_type=${type}&error=${error}&product=${product}`
  );
}

function send_notification(data) {
  return http.post(`/officeuser/notification`, data);
}

function get_sms_reports(page) {
  return http.get(`/officeuser/sms/reports?page=${page}`);
}

function get_notif_reports(page) {
  return http.get(`/officeuser/notification/reports?page=${page}`);
}

function get_zero_data(cityId) {
  return http.get(`officeuser/send/zerodata?city=${cityId}`);
}

function get_zero_data_reports(page) {
  return http.get(`/officeuser/zerodata/reports?page=${page}`);
}

function post_zero_data(data) {
  return http.post("officeuser/send/zerodata", data);
}

function get_offer_page_header() {
  return http.get("officeuser/price/page");
}

function post_offer_page_header(data) {
  return http.post("officeuser/price/page", data);
}

function get_packages() {
  return http.get("officeuser/package");
}

function post_package(data) {
  return http.post("officeuser/package", data);
}

function get_package_products(id) {
  return http.get(`officeuser/package/${id}/products`);
}

function get_product_types() {
  return http.get("officeuser/package/products/types");
}

function get_product_detail(packageId, productId) {
  return http.get(`officeuser/package/${packageId}/products/${productId}`);
}

function patch_product_detail(packageId, productId, data) {
  return http.patch(
    `officeuser/package/${packageId}/products/${productId}`,
    data
  );
}

function post_package_questions(packageId, data) {
  return http.post(`officeuser/package/${packageId}/questions`, data);
}

function add_product_to_package(packageId, data) {
  return http.post(`officeuser/package/${packageId}/products`, data);
}

function add_modules_to_product(productId, data) {
  return http.post(`officeuser/package/products/${productId}/modules`, data);
}

function delete_summary(productId, summaryId) {
  return http.delete(
    `officeuser/package/products/${productId}/features/${summaryId}`
  );
}

function edit_module(productId, moduleId, data) {
  return http.patch(
    `officeuser/package/products/${productId}/modules/${moduleId}`,
    data
  );
}

function delete_package(packageId) {
  return http.delete(`officeuser/package/${packageId}`);
}

function delete_module(productId, moduleId) {
  return http.delete(
    `officeuser/package/products/${productId}/modules/${moduleId}`
  );
}

function delete_product(packageId, productId) {
  return http.delete(`officeuser/package/${packageId}/products/${productId}`);
}

function delete_question(packageId, questionId) {
  return http.delete(`officeuser/package/${packageId}/questions/${questionId}`);
}

function edit_question(packageId, questionId, questionsData) {
  return http.patch(
    `officeuser/package/${packageId}/questions/${questionId}`,
    questionsData
  );
}

function delete_module_header(productId, moduleId) {
  return http.delete(
    `officeuser/package/products/${productId}/modules/headers/${moduleId}`
  );
}

function get_optional_products() {
  return http.get("officeuser/all/products");
}

function data_search_result(searchValue) {
  return http.get(`officeuser/application/search?search=${searchValue}`);
}

function patch_data_result(data) {
  return http.patch(`officeuser/application/search`, data);
}

function get_approved_offers() {
  return http.get(`officeuser/offer/special/basketdetail`);
}

function approve_offer(data) {
  return http.patch(`officeuser/offer/special/basketdetail`, data);
}

function seller_user_list() {
  return http.get(`/officeuser/seller/users/list`);
}

function get_kpi_outbound(data) {
  return http.get(
    `/officeuser/manager/kpi/outbound?start=${data.startDate}&end=${data.endDate}&officeuser=${data.userId}`
  );
}

function get_kpi_setup(data) {
  return http.get(
    `/officeuser/manager/kpi/setup?start=${data.startDate}&end=${data.endDate}&officeuser=${data.userId}`
  );
}

function get_kpi_collector(data) {
  return http.get(
    `/officeuser/manager/kpi/collector?start=${data.startDate}&end=${data.endDate}&officeuser=${data.userId}`
  );
}

function get_kpi_tickets(data) {
  return http.get(
    `/officeuser/manager/kpi/ticket?start=${data.startDate}&end=${data.endDate}&officeuser=${data.userId}`
  );
}

function get_setup_count(data) {
  return http.get(
    `/officeuser/manager/finance/report/setups?start=${data.startDate}&end=${data.endDate}`
  );
}

function get_sms_settings() {
  return http.get(`/officeuser/sms/patterns`);
}

function send_sms_setting(data) {
  return http.post(`/officeuser/sms/patterns`, data);
}

function edit_sms_setting(data) {
  return http.patch(`/officeuser/sms/patterns/${data.id}`, data);
}

function delete_sms_setting(id) {
  return http.delete(`/officeuser/sms/patterns/${id}`);
}

function get_collector_data(data) {
  return http.get(
    `/officeuser/manager/finance/amount/detail?start=${data.startDate}&end=${data.endDate}`
  );
}

function get_collector_transaction_data(data) {
  return http.get(
    `/officeuser/manager/finance/transactions/detail?start=${data.startDate}&end=${data.endDate}&&type=${data.type}`
  );
}

function get_collector_data_renew(data) {
  return http.get(
    `/officeuser/manager/finance/amount/detail?start=${data.startDate}&end=${data.endDate}&type=renew`
  );
}

function get_kpi_state_history(data) {
  return http.get(
    `/officeuser/manager/kpi/state_history?day=${data.day}&officeuser=${data.userId}`
  );
}

function get_mma_total(data) {
  return http.get(
    `/officeuser/manager/finance/mma/detail?start=${data.startDate}&end=${data.endDate}`
  );
}

function get_current_list(page, rowsPerPage) {
  return http.get(
    `officeuser/accounting/currentless/list?page=${page}&&page_size=${rowsPerPage}`
  );
}

function create_current() {
  return http.post(`officeuser/accounting/create_current`);
}

function get_note_templates() {
  return http.get(`officeuser/note_template`);
}

function create_note_template(data) {
  return http.post(`officeuser/note_template`, data);
}

function edit_note_template(data) {
  return http.patch(`officeuser/note_template/${data.tempId}`, data.obj);
}

function delete_note_template(id) {
  return http.delete(`officeuser/note_template/${id}`);
}

function sort_note_templates(data) {
  return http.post(`officeuser/note_template/sort`, data);
}

function manage_prim_settings() {
  return http.get(`officeuser/prim/setting`);
}

function change_prim_settings(data) {
  return http.post(`officeuser/prim/setting`, data);
}

function dashboard_officeuser() {
  return http.get(`/officeuser/general/dashboard`);
}

function dashboard_officeuser_v2() {
  return http.get(`/officeuser/general/dashboard/v2`);
}

function get_current_state() {
  return http.get(`/officeuser/current_state`);
}

function dashboard_officeuser_detail(data) {
  return http.get(
    `/officeuser/general/dashboard/${data.user_id}?start=${data.startDate}&end=${data.endDate}`
  );
}

function dashboard_officeuser_delete_reasons(data) {
  return http.get(
    `/officeuser/general/dashboard/${data.user_id}/application_delete_reasons?start=${data.startDate}&end=${data.endDate}`
  );
}

function dashboard_officeuser_blocked_reasons(data) {
  return http.get(
    `/officeuser/general/dashboard/${data.user_id}/restofficial_blocked_reasons?start=${data.startDate}&end=${data.endDate}`
  );
}

function dashboard_officeuser_application_types(data) {
  return http.get(
    `/officeuser/general/dashboard/${data.user_id}/application_types?start=${data.startDate}&end=${data.endDate}`
  );
}

function dashboard_officeuser_payed_offers(data) {
  return http.get(
    `/officeuser/general/dashboard/${data.user_id}/payed_offers?start=${data.startDate}&end=${data.endDate}&product=${data.productId}`
  );
}

function dashboard_officeuser_completed_tasks(data) {
  return http.get(
    `/officeuser/general/dashboard/${data.user_id}/completed_tasks?start=${
      data.startDate
    }&end=${data.endDate}&page=${data.page + 1}`
  );
}

function dashboard_officeuser_calendar(data) {
  return http.get(`/officeuser/general/dashboard/${data.user_id}/calendar`);
}

function dashboard_officeuser_calendar_tech(data) {
  return http.get(
    `/officeuser/general/dashboard/tech/${data.user_id}/calendar`
  );
}

function dashboard_officeuser_task_repeats(data) {
  return http.get(
    `/officeuser/general/dashboard/${data.user_id}/task_repeats?start=${data.startDate}&end=${data.endDate}`
  );
}

function dashboard_officeuser_task_repeats_dates(data) {
  if (data.restofficial_id) {
    return http.get(
      `/officeuser/general/dashboard/${data.user_id}/task_repeat/dates?start=${data.startDate}&end=${data.endDate}&task_type=${data.task_type}&restofficial_id=${data.restofficial_id}`
    );
  } else {
    return http.get(
      `/officeuser/general/dashboard/${data.user_id}/task_repeat/dates?start=${data.startDate}&end=${data.endDate}&task_type=${data.task_type}&application_id=${data.application_id}`
    );
  }
}

function dashboard_officeuser_active_status(data) {
  return http.post(`/officeuser/detail/${data.user_id}/active`, data.obj);
}

function dashboard_officeuser_task_lock(data) {
  return http.patch(`/officeuser/detail/${data.user_id}`, data.obj);
}

function dashboard_officeuser_delete(user_id) {
  return http.post(`/officeuser/detail/${user_id}/delete`);
}

function dashboard_officeuser_transfer(data) {
  return http.post(`officeuser/data/transfer`, data);
}

function dashboard_officeuser_transfer_restofficials(data) {
  return http.post(`officeuser/data/transfer/restofficials`, data);
}

function dashboard_officeuser_restofficials(user_id) {
  return http.get(
    `/officeuser/data/transfer/restofficial/list?origin_collector_id=${user_id}`
  );
}

function dashboard_officeuser_product_renewals(data) {
  return http.get(
    `officeuser/general/dashboard/${data.user_id}/product_renewals?start=${data.startDate}&end=${data.endDate}`
  );
}

function dashboard_officeuser_product_renewals_restaurants(data) {
  return http.get(
    `officeuser/general/dashboard/${data.user_id}/product_renewal/restaurants?start=${data.startDate}&end=${data.endDate}&product=${data.product_id}&process_type=${data.processType}`
  );
}

function dashboard_officeuser_info(data) {
  return http.get(
    `officeuser/general/dashboard/${data.user_id}/restaurants/${
      data.type
    }?start=${data.startDate}&end=${data.endDate}&page=${data.page + 1}`
  );
}

function add_dictionary_header(data) {
  return http.post(`officeuser/dictionary/headers`, data);
}

function edit_dictionary_header(data) {
  return http.patch(`officeuser/dictionary/headers/${data.id}`, data);
}

function delete_dictionary_header(id) {
  return http.delete(`officeuser/dictionary/headers/${id}`);
}

function get_dictionary_headers(searchValue) {
  return http.get(`officeuser/dictionary/headers?search=${searchValue}`);
}

function add_dictionary_feature(data, headerId) {
  return http.post(`officeuser/dictionary/headers/${headerId}/features`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

function get_dictionary_features(headerId) {
  return http.get(`officeuser/dictionary/headers/${headerId}/features`);
}

function delete_dictionary_feature(headerId, featureId) {
  return http.delete(
    `officeuser/dictionary/headers/${headerId}/features/${featureId}`
  );
}

function edit_dictionary_feature(headerId, featureId, data) {
  return http.patch(
    `officeuser/dictionary/headers/${headerId}/features/${featureId}`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

function dashboard_officeuser_tech() {
  return http.get(`/officeuser/general/dashboard/tech`);
}

function dashboard_officeuser_tech_detail(data) {
  return http.get(
    `/officeuser/general/dashboard/tech/${data.user_id}?start=${data.startDate}&end=${data.endDate}`
  );
}

function dashboard_officeuser_tech_active_tasks(data) {
  return http.get(
    `officeuser/general/dashboard/${data.user_id}/active_tasks?start=${data.startDate}&end=${data.endDate}`
  );
}

function dashboard_officeuser_tech_ticket_bounces(data) {
  return http.get(
    `officeuser/general/dashboard/${data.user_id}/ticket_bounces?start=${
      data.startDate
    }&end=${data.endDate}&page=${data.page + 1}`
  );
}

function finance_offer(data) {
  return http.get(`/officeuser/finance/offer?restofficial_id=${data?.rest_id}`);
}

function finance_offer_change(data) {
  return http.post(
    `/officeuser/finance/offer?restofficial_id=${data?.rest_id}`,
    data.sendObj
  );
}

function accountingCurrentToKunye(data) {
  return http.get(
    `/officeuser/accounting/restaurant/current_code?search=${data.search}`
  );
}

// * sms

function create_sms_template(data) {
  return http.post(`/officeuser/sms_template`, data.sendObj);
}

function create_sms_template_test(data) {
  return http.post(`/officeuser/sms_template/test`, data.sendObj);
}

function sms_template_list() {
  return http.get(`/officeuser/sms_template`);
}

function platform_and_products() {
  return http.get(`/officeuser/platforms_and_products`);
}

function publish_sms(data) {
  return http.post(`/officeuser/sms/send`, data.sendObj);
}

function popup_template_list() {
  return http.get(`/officeuser/popup/template`);
}

function create_popup_template(data) {
  const bodyFormData = new FormData();
  bodyFormData.append("name", data.sendObj.name);
  bodyFormData.append("content", data.sendObj.content);
  bodyFormData.append("popup_type", data.sendObj.popup_type);
  bodyFormData.append("package_products", data.sendObj.package_products);
  bodyFormData.append("popup_actions", data.sendObj.popup_actions);
  bodyFormData.append("platforms", data.sendObj.platforms);
  bodyFormData.append("custom_application", data.sendObj.custom_application);
  bodyFormData.append("forward_to", data.sendObj.forward_to);
  bodyFormData.append("image", data.sendObj.image);
  bodyFormData.append("image2", data.sendObj.image2);

  return http.post(`/officeuser/popup/template`, bodyFormData, {
    "Content-Type": "multipart/form-data",
  });
}

function button_actions() {
  return http.get(`/officeuser/button_actions`);
}

function publish_popup(data) {
  return http.post(`/officeuser/popup/publish`, data.sendObj);
}

function banner_template_list() {
  return http.get(`/officeuser/banner/template`);
}

function create_banner_template(data) {
  const bodyFormData = new FormData();
  bodyFormData.append("name", data.sendObj.name);
  bodyFormData.append("content", data.sendObj.content);
  bodyFormData.append("banner_type", data.sendObj.banner_type);
  bodyFormData.append("package_products", data.sendObj.package_products);
  bodyFormData.append("banner_actions", data.sendObj.banner_actions);
  bodyFormData.append("platforms", data.sendObj.platforms);
  bodyFormData.append("custom_application", data.sendObj.custom_application);
  bodyFormData.append("forward_to", data.sendObj.forward_to);
  bodyFormData.append("image", data.sendObj.image);
  bodyFormData.append("image2", data.sendObj.image2);
  bodyFormData.append("priority", data.sendObj.priority.value);
  bodyFormData.append("show_time", data.sendObj.show_time.value);
  bodyFormData.append("blacklist_on_click", data.sendObj.blacklist_on_click);

  return http.post(`/officeuser/banner/template`, bodyFormData, {
    "Content-Type": "multipart/form-data",
  });
}

function publish_banner(data) {
  return http.post(`/officeuser/banner/publish`, data.sendObj);
}

function delete_banner_template(data) {
  return http.delete(`/officeuser/banner/template/${data.temp_id}`);
}

function banner_template_change(data) {
  const bodyFormData = new FormData();
  bodyFormData.append("name", data.sendObj.name);
  bodyFormData.append("content", data.sendObj.content);
  bodyFormData.append("forward_to", data.sendObj.forward_to);
  if (data.sendObj.image !== null) {
    bodyFormData.append("image", data.sendObj.image);
  }
  if (data.sendObj.image2 !== null) {
    bodyFormData.append("image2", data.sendObj.image2);
  }
  bodyFormData.append("priority", data.sendObj.priority.value);
  bodyFormData.append("show_time", data.sendObj.show_time.value);
  bodyFormData.append("blacklist_on_click", data.sendObj.blacklist_on_click);

  return http.patch(
    `/officeuser/banner/template/${data.temp_id}`,
    bodyFormData,
    {
      "Content-Type": "multipart/form-data",
    }
  );
}

function banner_list(data) {
  return http.get(`/officeuser/banner/list?page=${data.page}`);
}

function banner_change(data) {
  return http.patch(`/officeuser/banner/${data.banner_id}`, data.sendObj);
}

function get_printing_press_list() {
  return http.get(`/officeuser/printing_press`);
}

function create_printing_press_user(data) {
  return http.post(`/officeuser/printing_press`, data);
}

function edit_printing_press_user(data) {
  return http.patch(`/officeuser/printing_press/${data.user.id}`, data);
}

function get_baski_types() {
  return http.get(`/officeuser/printing_type_template`);
}

function create_new_baski(data) {
  return http.post(`/officeuser/printing_type_template`, data);
}

function edit_baski_detail(data) {
  return http.patch(`officeuser/printing_type_template/${data.id}`, data);
}

function get_printing_request_list(page, restId) {
  return http.get(
    `/officeuser/printing_request?page=${page}&restofficial_id=${restId}`
  );
}

function get_brochure_picture(id) {
  return http.get(`/officeuser/printing_request/${id}/download`);
}

function brochure_pic_download(data) {
  return http.get(
    `/officeuser/printing_request/${data?.id}/download?return_format=${data?.return_format}&&file_type=${data?.file_type}`,
    {
      responseType:
        data?.type === "pdf" || data?.type === "jpeg" ? "blob" : "json",
    }
  );
}

function brochure_send_restaurant(id) {
  return http.post(`/officeuser/printing_request/${id}/send`);
}

function get_printing_request_detail(id) {
  return http.get(`/officeuser/printing_request/${id}`);
}

function edit_printing_request_detail(id, data) {
  return http.patch(`/officeuser/printing_request/${id}`, data);
}

function get_baski_requests_finance(page, startDate, endDate, status) {
  return http.get(
    `/officeuser/manager/finance/printing_request?page=${page}&start_date=${startDate}&end_date=${endDate}&status=${status}`
  );
}

function action_printing_request_finance(data, id) {
  return http.patch(`/officeuser/manager/finance/printing_request/${id}`, data);
}

function daily_payment() {
  return http.get(`/officeuser/accounting/daily_payment_excel/list`);
}

function daily_payment_patch(data) {
  return http.patch(`/officeuser/accounting/daily_payment_excel/${data.id}`, {
    payed: true,
  });
}

function daily_payment_delete(data) {
  return http.delete(`/officeuser/accounting/daily_payment_excel/${data.id}`);
}

function daily_payment_exel() {
  return http.post(`/officeuser/accounting/daily_payment_excel`);
}

function state_history_past_list(data) {
  return http.get(
    `/officeuser/state_history/past?date=${data.sendingObj.date}&&officeuser=${data.sendingObj.officeuser}`
  );
}

function create_printing_request(data) {
  return http.post(`/officeuser/printing_request`, data);
}

function get_product_prim_settings(productId) {
  return http.get(`officeuser/prim/setting?product=${productId}`);
}

function get_product_list() {
  return http.get(`officeuser/prim/product/list`);
}

function send_prim_settings(data) {
  return http.post(`officeuser/prim/setting`, data);
}

function get_offer_detail(offerId, discount, discountRenew) {
  return http.get(
    `officeuser/offer/${offerId}/discounts?discount_new=${discount}&discount_renew=${discountRenew}`
  );
}

function patch_discount_offer(offerId, data) {
  return http.patch(`officeuser/offer/${offerId}/discounts`, data);
}

function get_extra_prim_settings() {
  return http.get(`officeuser/extra/prim/setting`);
}

function add_extra_prim_settings(data) {
  return http.post(`officeuser/extra/prim/setting`, data);
}

function get_office_user(userId) {
  return http.get(`/officeuser/${userId}`);
}

function create_office_user(data) {
  return http.post(`/officeuser/user/create`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

function edit_office_user(data, userId) {
  return http.patch(`/officeuser/${userId}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

function get_user_roles() {
  return http.get(`officeuser/roles`);
}

function get_user_subdepartments() {
  return http.get(`officeuser/subdepartments`);
}

function get_user_departments() {
  return http.get(`officeuser/departments`);
}

function daskboard_cdr_list(data) {
  return http.get(`/officeuser/cdr/list`, {
    params: data.sendObj,
  });
}

function daskboard_cdr_filters() {
  return http.get(`/officeuser/cdr/filters`);
}

function daskboard_cdr_stats(data) {
  return http.get(`/officeuser/cdr/stats`, {
    params: data.sendObj,
  });
}

function rating_setting_add(data) {
  return http.post(
    `/officeuser/rating/settings/${data.rating_setting_id}/add`,
    data.sendObj
  );
}

function rating_setting_delete(data) {
  return http.delete(
    `officeuser/rating/settings/${data.rating_setting_id}/remove/${data.feature_setting_id}`
  );
}

function cdr_repeats_list(data) {
  return http.get(
    `/officeuser/general/dashboard/${data.officeuser_id}/cdr/repeats`,
    {
      params: data.sendObj,
    }
  );
}

function cdr_outbound_duration_list(data) {
  return http.get(
    `/officeuser/general/dashboard/${data.officeuser_id}/cdr/outbound/durations`,
    {
      params: data.sendObj,
    }
  );
}

function cdr_inbound_duration_list(data) {
  return http.get(
    `/officeuser/general/dashboard/${data.officeuser_id}/cdr/inbound/durations`,
    {
      params: data.sendObj,
    }
  );
}

function tahsilat_rating(data) {
  return http.get(`/officeuser/rating/${data.rating_id}`);
}

function tahsilat_rating_skip(data) {
  return http.post(`/officeuser/rating/${data.rating_id}/skip`);
}

function reference_payments(data) {
  return http.get(`/officeuser/reference/payments?payed=${data.payed}`);
}

function reference_payed(data) {
  return http.post(`officeuser/reference/payment/${data.payed_id}/payed`);
}

function delete_pdf_from_dictionary(id) {
  return http.delete(`officeuser/dictionary/features/files/${id}`);
}

function order_dictionary_features(id, data) {
  return http.patch(
    `officeuser/dictionary/headers/${id}/features/ordering`,
    data
  );
}

function get_multi_product_discounts() {
  return http.get(`officeuser/multi_product_discounts`);
}

function update_multi_product_discounts(data) {
  return http.post(`officeuser/multi_product_discounts`, data);
}

//* cdr rating

function cdr_rating_header_list() {
  return http.get(`/officeuser/cdrrating/header`);
}

function cdr_rating_header_create(data) {
  return http.post(`/officeuser/cdrrating/header`, data.sendObj);
}

function cdr_rating_header_update(data) {
  return http.post(
    `/officeuser/cdrrating/header/${data.id}/update`,
    data.sendObj
  );
}

function cdr_rating_group_list() {
  return http.get(`/officeuser/cdrrating/group`);
}

function cdr_rating_group_create(data) {
  return http.post(`/officeuser/cdrrating/group`, data.sendObj);
}

function cdr_rating_group_update(data) {
  return http.post(
    `/officeuser/cdrrating/group/${data.id}/update`,
    data.sendObj
  );
}

function rating_settings_list() {
  return http.get(`/officeuser/cdrrating/settings`);
}

function rating_settings_create(data) {
  return http.post(`/officeuser/cdrrating/settings`, data.sendObj);
}

function cdr_rating_settings_header(data) {
  return http.post(
    `/officeuser/cdrrating/setting/${data.id}/header`,
    data.sendObj
  );
}

function rating_list(data) {
  return http.get(`/officeuser/cdrrating/list`, {
    params: data.sendObj,
  });
}

function cdr_rating_tasktype_create(data) {
  return http.post(`/officeuser/cdrrating/task_type/create`, data.sendObj);
}

function rating_rate(data) {
  return http.post(
    `/officeuser/cdrrating/${data.rating_id}/rate`,
    data.sendObj
  );
}

function rating_education_rate(data) {
  return http.post(
    `/officeuser/cdrrating/${data.rating_id}/education`,
    data.sendObj
  );
}

function rating_stats(data) {
  return http.get(`/officeuser/cdrrating/stats`, {
    params: data.sendObj,
  });
}

function rating_tasktype_stats(data) {
  return http.get(`/officeuser/cdrrating/task_type/stats`, {
    params: data.sendObj,
  });
}

function cdr_header_delete(data) {
  return http.delete(`/officeuser/cdrrating/header/${data.id}`);
}

function cdr_group_delete(data) {
  return http.delete(`/officeuser/cdrrating/group/${data.id}`);
}

function cdr_rating_group_add(data) {
  return http.post(`/officeuser/cdrrating/${data.id}/group/add`, data.sendObj);
}

function cdr_rating_detail(data) {
  return http.get(`/officeuser/cdrrating/${data.id}`);
}

function cdr_rating_notification_read(data) {
  return http.post(`/officeuser/cdrrating/${data.id}/notification_read`);
}

function cdr_rating_skip(data) {
  return http.post(`/officeuser/cdrrating/${data?.id}/skip`);
}

function cdr_rating_education_history(data) {
  return http.get(`/officeuser/cdrrating/educations`, {
    params: data.sendObj,
  });
}

function cdr_rating_settings_patch(data) {
  return http.patch(`/officeuser/cdrrating/setting/${data.id}`, data.sendObj);
}

function cdr_rating_create(data) {
  return http.post(`/officeuser/cdrrating/cdr/create`, data.sendObj);
}

function get_users_setup_prim() {
  return http.get(`officeuser/setup/prim`);
}

function add_user_setup_prim(data) {
  return http.post(`officeuser/setup/prim`, data);
}

function set_bundle_products(data) {
  return http.post(`officeuser/bundle/prim/products`, data);
}

function get_bundle_products() {
  return http.get(`officeuser/bundle/prim/products`);
}

function get_bundle_prim_settings() {
  return http.get(`officeuser/bundle/prim/settings`);
}

function add_bundle_prim_settings(data) {
  return http.post(`officeuser/bundle/prim/settings`, data);
}
//*

const managementService = {
  officeuser_costs,
  officeuser_costs_change,
  paidable_prim,
  paidable_prim_approve,
  get_manager_works,
  manager_selected_reports,
  get_payment_tracker,
  get_graphs,
  get_application_types,
  get_services_reports,
  get_services_reports_rating,
  get_restaurant_services,
  get_restaurant_services_graphs,
  permission_application_types,
  permission_application_people,
  patch_application_type,
  campaign_sms_types,
  blocked_reasons,
  getSms,
  postSmsCampaign,
  get_notification_type,
  send_notification,
  get_sms_reports,
  get_zero_data,
  post_zero_data,
  get_notif_reports,
  get_zero_data_reports,
  get_packages,
  post_package,
  get_package_products,
  get_offer_page_header,
  post_offer_page_header,
  get_product_types,
  get_product_detail,
  post_package_questions,
  add_product_to_package,
  add_modules_to_product,
  patch_product_detail,
  delete_summary,
  edit_module,
  delete_product,
  delete_module,
  delete_package,
  delete_question,
  delete_module_header,
  get_optional_products,
  edit_question,
  data_search_result,
  patch_data_result,
  get_approved_offers,
  approve_offer,
  seller_user_list,
  get_kpi_outbound,
  get_kpi_setup,
  get_kpi_collector,
  get_setup_count,
  get_sms_settings,
  send_sms_setting,
  edit_sms_setting,
  delete_sms_setting,
  get_collector_data,
  get_kpi_tickets,
  get_kpi_state_history,
  get_mma_total,
  get_current_list,
  create_current,
  get_note_templates,
  create_note_template,
  sort_note_templates,
  edit_note_template,
  delete_note_template,
  update_services_reports,
  manage_prim_settings,
  change_prim_settings,
  get_collector_data_renew,
  dashboard_officeuser,
  get_current_state,
  dashboard_officeuser_detail,
  dashboard_officeuser_delete_reasons,
  dashboard_officeuser_blocked_reasons,
  dashboard_officeuser_application_types,
  dashboard_officeuser_payed_offers,
  dashboard_officeuser_completed_tasks,
  dashboard_officeuser_calendar,
  dashboard_officeuser_task_repeats,
  dashboard_officeuser_task_repeats_dates,
  dashboard_officeuser_active_status,
  dashboard_officeuser_task_lock,
  dashboard_officeuser_delete,
  dashboard_officeuser_transfer,
  dashboard_officeuser_restofficials,
  dashboard_officeuser_transfer_restofficials,
  dashboard_officeuser_product_renewals,
  dashboard_officeuser_product_renewals_restaurants,
  dashboard_officeuser_info,
  add_dictionary_header,
  get_dictionary_headers,
  edit_dictionary_header,
  delete_dictionary_header,
  add_dictionary_feature,
  get_dictionary_features,
  delete_dictionary_feature,
  edit_dictionary_feature,
  dashboard_officeuser_tech,
  dashboard_officeuser_tech_detail,
  dashboard_officeuser_calendar_tech,
  dashboard_officeuser_tech_active_tasks,
  dashboard_officeuser_tech_ticket_bounces,
  get_collector_transaction_data,
  finance_offer,
  finance_offer_change,
  accountingCurrentToKunye,
  create_sms_template,
  sms_template_list,
  create_sms_template_test,
  platform_and_products,
  publish_sms,
  popup_template_list,
  create_popup_template,
  button_actions,
  publish_popup,
  banner_template_list,
  create_banner_template,
  publish_banner,
  delete_banner_template,
  banner_template_change,
  banner_list,
  banner_change,
  create_printing_press_user,
  get_printing_press_list,
  edit_printing_press_user,
  create_new_baski,
  get_baski_types,
  edit_baski_detail,
  get_printing_request_list,
  get_brochure_picture,
  get_printing_request_detail,
  edit_printing_request_detail,
  get_baski_requests_finance,
  daily_payment,
  daily_payment_patch,
  daily_payment_delete,
  daily_payment_exel,
  action_printing_request_finance,
  state_history_past_list,
  create_printing_request,
  brochure_pic_download,
  get_product_prim_settings,
  get_product_list,
  send_prim_settings,
  get_offer_detail,
  brochure_send_restaurant,
  get_extra_prim_settings,
  add_extra_prim_settings,
  patch_discount_offer,
  create_office_user,
  get_user_roles,
  get_user_departments,
  get_user_subdepartments,
  get_office_user,
  edit_office_user,
  daskboard_cdr_list,
  daskboard_cdr_filters,
  daskboard_cdr_stats,
  rating_settings_list,
  rating_list,
  dashboard_officeuser_v2,
  rating_rate,
  rating_education_rate,
  rating_setting_add,
  rating_setting_delete,
  cdr_repeats_list,
  cdr_outbound_duration_list,
  cdr_inbound_duration_list,
  tahsilat_rating,
  tahsilat_rating_skip,
  reference_payments,
  reference_payed,
  delete_pdf_from_dictionary,
  order_dictionary_features,
  get_multi_product_discounts,
  update_multi_product_discounts,
  cdr_rating_header_list,
  cdr_rating_header_create,
  cdr_rating_header_update,
  cdr_rating_group_list,
  cdr_rating_group_create,
  cdr_rating_group_update,
  rating_settings_create,
  cdr_rating_settings_header,
  cdr_rating_create,
  cdr_rating_tasktype_create,
  rating_stats,
  rating_tasktype_stats,
  cdr_header_delete,
  cdr_rating_group_add,
  cdr_rating_detail,
  cdr_rating_notification_read,
  cdr_rating_skip,
  cdr_rating_education_history,
  cdr_group_delete,
  cdr_rating_settings_patch,
  get_users_setup_prim,
  add_user_setup_prim,
  set_bundle_products,
  get_bundle_products,
  get_bundle_prim_settings,
  add_bundle_prim_settings,
};

export default managementService;
