import React, {
  useState,
  useRef,
  createRef,
  useContext,
  useEffect,
} from "react";
import { RestaurantContext } from "../../../../../contexts/restaurantContext";
//mui
import { AddCircleOutlined, DeleteSweep } from "@mui/icons-material";
import {
  Box,
  Paper,
  Divider,
  IconButton,
  Button,
  InputBase,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
//components
import SearchWithIcon from "../../../../../components/searchWithIcon";
import ProductOpenOrClose from "../../components/productOpenOrClose";
//Img
import emptyPhoto from "../../../../../utils/assets/emptyPhoto.jpg";
import uploadPhoto from "../../../../../utils/assets/uploadPhoto.png";
//react-select
import Select from "react-select";
import { selectDefaultStyles } from "../../../../../components/selectStyles/stylev4";
//const arr
import { discountArray } from "./discountArray";
import ProductImportModal from "../../../../../components/modals/productImportModal";
import AllowPhotoModal from "../../../../../components/modals/allowPhotoModal";
import { GreenSwitch } from "../../../../../components/switch/greenSwitch";
import restaurantService from "../../../../../services/restaurantService";

const useStyles = makeStyles((theme) => ({
  overflowStyle: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 15,
    scrollbarWidth: "thin",
    cursor: "pointer",
    scrollbarColor: "#B7B7B7 gray",
    "&::-webkit-scrollbar": {
      height: 8,
      backgroundColor: "#f4f4f4",
      borderRadius: 4,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 6,
      background: `linear-gradient(to right,  ${"royalblue"} 0%,${"#f1b62a"} 100%)`,
      minHeight: 12,
      minWidth: 12,
      width: 12,
    },
    "&::-webkit-scrollbar-thumb:focus": {
      background: `linear-gradient(to right,  ${"royalblue"} 0%,${"#f1b62a"} 100%)`,
    },
  },
}));
function MenuList({
  productHeaderList,
  /* productList, */
  setCreateHeaderModal,
  setCurrentHeader,
  setIsFlipped,
  setProductObj,
  setProductAddOrUpdate,
  id,
  setDeleteType,
  setProductDeleteModal,
  actionType,
  setCurrentProductType,
  setProductPhotoModal,
  resChangeDetailFunc,
  resDetailInfo,
  setSortListModal,
  setSortList,
  setSortType,
}) {
  const classes = useStyles();
  const {
    menuBulkUpdateFunc,
    menuDetailChangeFunc,
    menuBulkUpdatePriceFunc,
    platformList,
    getPlatformList,
    getRestaurantInfo,
    restOfficialData,
    getProductHeaderList,
    getProductList,
  } = useContext(RestaurantContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [bulkList, setBulkList] = useState(null);
  const [selectedHeader, setSelectedHeader] = useState("header_0");
  const [selectedGeneralDiscount, setSelectedGeneralDiscount] = useState(null);
  const [selectedOrderDiscount, setSelectedOrderDiscount] = useState(null);
  const [selectedCouponDiscount, setSelectedCouponDiscount] = useState(null);
  const [selectedCouponMinDiscount, setSelectedCouponMinDiscount] =
    useState(null);
  const scrollRefs = useRef([]);
  const [openProductImportModal, setOpenProductImportModal] = useState(false);
  const [openAllowPhotoModal, setOpenAllowPhotoModal] = useState(false);

  scrollRefs.current = [...Array(productHeaderList?.length).keys()].map(
    (_, i) => scrollRefs.current[i] ?? createRef()
  );

  const scrollSmoothHandler = (index) => () => {
    setSelectedHeader(`header_${index}`);
    scrollRefs.current[index]?.current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    if (resDetailInfo != null) {
      setSelectedGeneralDiscount({
        id: resDetailInfo?.order_discount_percent,
        name: resDetailInfo?.order_discount_percent,
      });
      setSelectedCouponDiscount({
        id: resDetailInfo?.first_order_discount_amount,
        name: resDetailInfo?.first_order_discount_amount,
      });
      setSelectedOrderDiscount({
        id: resDetailInfo?.cashback_percent,
        name: resDetailInfo?.cashback_percent,
      });
      setSelectedCouponMinDiscount({
        id: resDetailInfo?.first_order_discount_min_price,
        name: resDetailInfo?.first_order_discount_min_price,
      });
    }
  }, [resDetailInfo]);

  const handleDiscountUpdate = (type, count) => {
    switch (type) {
      case "general":
        resChangeDetailFunc({
          order_discount_percent: count,
          restofficial_id: id,
        });
        break;
      case "coupon":
        resChangeDetailFunc({
          first_order_discount_amount: count,
          restofficial_id: id,
        });
        break;
      case "order":
        resChangeDetailFunc({
          cashback_percent: count,
          restofficial_id: id,
        });
        break;
      case "coupon_2":
        resChangeDetailFunc({
          first_order_discount_min_price: count,
          restofficial_id: id,
        });
        break;
      default:
        break;
    }
  };

  const handleCopyProduct = async (copiedProduct) => {
    const data = {
      product_id: copiedProduct.id,
    };
    const product = await restaurantService.menu_detail(data);
    product.name = product.name + " " + crypto.randomUUID();
    delete product.id;

    let dataProduct = {
      sendingObj: { product, restofficial_id: id },
    };

    await restaurantService.menu_product_create(dataProduct);

    getProductHeaderList(id);
    getProductList(id);
  };

  useEffect(() => {
    getPlatformList();
    getRestaurantInfo(id);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {actionType == "discount" && (
        <Paper
          elevation={1}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            minHeight: 80,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <span style={{ fontFamily: "segoeuiB" }}>
              Tüm Ürünlerde İndirim
            </span>
            <Select
              responsiveHeight={25}
              selectFontSize={16}
              options={discountArray}
              styles={selectDefaultStyles}
              isSearchable={false}
              zIndex={9999}
              hoverColor={"#f53b62"}
              menuColor={"#f53b62"}
              selectWidth={100}
              placeholder={"Tümü"}
              value={selectedGeneralDiscount}
              getOptionValue={(option) => option.id}
              onChange={(e) => {
                setSelectedGeneralDiscount(e);
                handleDiscountUpdate("general", e.id);
              }}
              getOptionLabel={(e) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  % {e.name}
                </div>
              )}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <span style={{ fontFamily: "segoeuiB" }}>İlk Siparişe İndirim</span>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: 20,
                }}
              >
                <span style={{ marginBottom: "-2px" }}>Aç / Kapat</span>
                <GreenSwitch
                  checked={resDetailInfo?.first_order_discount}
                  onClick={() => {
                    resChangeDetailFunc({
                      first_order_discount:
                        !resDetailInfo?.first_order_discount,
                      restofficial_id: id,
                    });
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: 20,
                }}
              >
                <span style={{ marginBottom: 6 }}>İndirim Tutarı</span>
                <Select
                  responsiveHeight={25}
                  selectFontSize={16}
                  options={discountArray}
                  styles={selectDefaultStyles}
                  isSearchable={false}
                  zIndex={9999}
                  hoverColor={"#f53b62"}
                  menuColor={"#f53b62"}
                  selectWidth={100}
                  placeholder={"Tümü"}
                  value={selectedCouponDiscount}
                  getOptionValue={(option) => option.id}
                  onChange={(e) => {
                    setSelectedCouponDiscount(e);
                    handleDiscountUpdate("coupon", e.id);
                  }}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      ₺ {e.name}
                    </div>
                  )}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span style={{ marginBottom: 6 }}>Minimum Tutar</span>
                <Select
                  responsiveHeight={25}
                  selectFontSize={16}
                  options={discountArray}
                  styles={selectDefaultStyles}
                  isSearchable={false}
                  zIndex={9999}
                  hoverColor={"#f53b62"}
                  menuColor={"#f53b62"}
                  selectWidth={100}
                  placeholder={"Tümü"}
                  value={selectedCouponMinDiscount}
                  getOptionValue={(option) => option.id}
                  onChange={(e) => {
                    setSelectedCouponMinDiscount(e);
                    handleDiscountUpdate("coupon_2", e.id);
                  }}
                  getOptionLabel={(e) => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      ₺ {e.name}
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <span style={{ fontFamily: "segoeuiB" }}>
              Her Siparişe SepetPay
            </span>
            <Select
              responsiveHeight={25}
              selectFontSize={16}
              options={discountArray}
              styles={selectDefaultStyles}
              isSearchable={false}
              zIndex={9999}
              hoverColor={"#f53b62"}
              menuColor={"#f53b62"}
              selectWidth={100}
              placeholder={"Tümü"}
              value={selectedOrderDiscount}
              getOptionValue={(option) => option.id}
              onChange={(e) => {
                setSelectedOrderDiscount(e);
                handleDiscountUpdate("order", e.id);
              }}
              getOptionLabel={(e) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  % {e.name}
                </div>
              )}
            />
          </div>
        </Paper>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          overflowX: "auto",
        }}
      >
        <div className={classes.overflowStyle} style={{ width: "99%" }}>
          {productHeaderList &&
            productHeaderList?.map((item, index) => (
              <Paper
                key={index}
                elevation={2}
                onClick={scrollSmoothHandler(index)}
                style={{
                  minWidth: 150,
                  maxWidth: 150,
                  height: 30,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  padding: 5,
                  margin: 15,
                  fontSize: 12,
                  color:
                    selectedHeader == `header_${index}` ? "royalblue" : "black",
                }}
              >
                <span style={{ fontWeight: "bold" }}>{item?.name}</span>
              </Paper>
            ))}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          marginBottom: 10,
          padding: 5,
        }}
      >
        <SearchWithIcon
          setSearchTerm={setSearchTerm}
          placeTitle={"Ürün Ara"}
          searchTerm={searchTerm}
        />
        <div style={{ display: "flex" }}>
          <div
            style={{
              backgroundColor: "#5a5757",
              padding: 2,
              cursor: "pointer",
              borderRadius: 4,
              color: "#fff",
              fontSize: 12,
              boxShadow: "2px 3px 2.5px 1px #C5C5BB",
            }}
            onClick={() => {
              setOpenProductImportModal(true);
            }}
          >
            Menü Aktarma
          </div>
          <div
            style={{
              backgroundColor: "#5a5757",
              marginLeft: 15,
              padding: 2,
              cursor: "pointer",
              borderRadius: 4,
              color: "#fff",
              fontSize: 12,
              boxShadow: "2px 3px 2.5px 1px #C5C5BB",
            }}
            onClick={() => {
              setSortType("header");
              setSortListModal(true);
              setSortList(productHeaderList);
            }}
          >
            Başlık Sırala
          </div>
          <div
            style={{
              marginLeft: 15,
              backgroundColor: "#5a5757",
              padding: 2,
              cursor: "pointer",
              borderRadius: 4,
              color: "#fff",
              fontSize: 12,
              boxShadow: "2px 3px 2.5px 1px #C5C5BB",
            }}
            onClick={() => {
              setCreateHeaderModal(true);
            }}
          >
            Başlık Ekle
          </div>
        </div>
      </div>

      {productHeaderList &&
        productHeaderList?.map((item, index) => (
          <Box
            key={index}
            ref={scrollRefs.current[index]}
            style={{
              width: "98%",
              borderRadius: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: 2,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "#5a5757",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    height: 100,
                    width: 100,
                    borderRadius: 10,
                    border: "1px solid darkgray",
                  }}
                  src={item?.picture === null ? emptyPhoto : item?.picture}
                />
                <span
                  style={{
                    marginLeft: 10,
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                >
                  {item?.name}
                </span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {actionType == "delete" ? (
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: 20,
                      }}
                    >
                      <span
                        style={{
                          color: "#fff",
                          marginBottom: 4,
                          fontWeight: "bold",
                        }}
                      >
                        GÖSTER
                      </span>
                      <Divider
                        sx={{
                          backgroundColor: "#f4f4f4",
                          width: 1,
                        }}
                      />
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <ProductOpenOrClose
                            title={"App"}
                            cond={
                              item?.products?.filter((a) => a?.sepetapp)
                                .length > 0
                            }
                            onClick={() => {
                              let data = {
                                menuObj: {
                                  product_header: item?.id,
                                  sepetapp: !(
                                    item?.products?.filter((a) => a?.sepetapp)
                                      .length > 0
                                  ),
                                },
                                restofficial_id: id,
                              };
                              menuBulkUpdateFunc(data);
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <ProductOpenOrClose
                            title={"Masa"}
                            cond={
                              item?.products?.filter((a) => a?.store).length > 0
                            }
                            onClick={() => {
                              let data = {
                                menuObj: {
                                  product_header: item?.id,
                                  store: !(
                                    item?.products?.filter((a) => a?.store)
                                      .length > 0
                                  ),
                                },
                                restofficial_id: id,
                              };
                              menuBulkUpdateFunc(data);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <Divider
                      orientation="vertical"
                      sx={{
                        backgroundColor: "#f4f4f4",
                        width: "1%",
                        height: "auto",
                        marginLeft: 2,
                      }}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        style={{
                          width: 48,
                          height: 48,
                          color: "#fff",
                          marginLeft: 20,
                        }}
                        onClick={() => {
                          setDeleteType("header");
                          let data = {
                            product_id: item?.id,
                            menuObj: {
                              name: item?.name,
                              is_deleted: true,
                            },
                            restofficial_id: id,
                          };
                          setProductDeleteModal(true);
                          setCurrentHeader(data);
                        }}
                      >
                        <DeleteSweep />
                      </IconButton>
                    </div>
                  </div>
                ) : actionType == "openOrClose" ? (
                  <>
                    {item?.products?.length !== 0 ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ProductOpenOrClose
                          title={"App"}
                          cond={
                            item?.products?.filter((a) => a?.sepetapp_is_active)
                              .length > 0
                          }
                          onClick={() => {
                            let data = {
                              menuObj: {
                                product_header: item?.id,
                                sepetapp_is_active: !(
                                  item?.products?.filter(
                                    (a) => a?.sepetapp_is_active
                                  ).length > 0
                                ),
                              },
                              restofficial_id: id,
                            };
                            menuBulkUpdateFunc(data);
                          }}
                        />
                        <ProductOpenOrClose
                          title={"Masa"}
                          cond={
                            item?.products?.filter((a) => a?.store_is_active)
                              .length > 0
                          }
                          onClick={() => {
                            let data = {
                              menuObj: {
                                product_header: item?.id,
                                store_is_active: !(
                                  item?.products?.filter(
                                    (a) => a?.store_is_active
                                  ).length > 0
                                ),
                              },
                              restofficial_id: id,
                            };
                            menuBulkUpdateFunc(data);
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : actionType === "photoUpload" ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: 100,
                      minWidth: 100,
                      height: "100%",
                    }}
                  >
                    <img
                      onClick={() => {
                        if (!resDetailInfo.generic_product_pictures_set) {
                          setOpenAllowPhotoModal(true);
                        } else {
                          setProductPhotoModal(true);
                        }
                        setCurrentHeader(item);
                        setCurrentProductType("header");
                      }}
                      style={{
                        width: "80%",
                        height: "80%",
                        borderRadius: 10,
                        cursor: "pointer",
                      }}
                      src={uploadPhoto}
                      alt="uploadPhoto"
                    />
                  </div>
                ) : (
                  ""
                )}
                <div
                  style={{
                    backgroundColor: "#fff",
                    padding: 2,
                    cursor: "pointer",
                    borderRadius: 4,
                    color: "#5a5757",
                    fontSize: 12,
                    boxShadow: "2px 3px 2.5px 1px #C5C5BB",
                    marginRight: 10,
                  }}
                  onClick={() => {
                    setSortType("product");
                    setSortListModal(true);
                    setSortList(item?.products);
                  }}
                >
                  Ürün Sırala
                </div>
                <AddCircleOutlined
                  style={{
                    width: 32,
                    height: 32,
                    cursor: "pointer",
                    marginRight: 20,
                    marginLeft: 20,
                    color: "#fff",
                  }}
                  onClick={() => {
                    setCurrentHeader(item);
                    setIsFlipped(true);
                    setProductAddOrUpdate(true);
                  }}
                />
              </div>
            </div>
            {item?.products
              ?.filter((list) =>
                list.name
                  .toLocaleLowerCase("tr")
                  .includes(searchTerm.toLocaleLowerCase("tr"))
              )
              .map((product, ind) => (
                <React.Fragment key={ind}>
                  <div
                    style={{
                      display: "flex",
                      marginLeft: 30,
                      marginTop: 10,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        backgroundColor: "#fff",
                        width: "80%",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIsFlipped(true);
                        setProductObj(product);
                        setCurrentHeader(product);
                        setProductAddOrUpdate(false);
                      }}
                    >
                      <img
                        style={{
                          height: 100,
                          width: 100,
                          borderRadius: 10,
                          border: "1px solid darkgray",
                        }}
                        src={
                          product?.picture === null
                            ? emptyPhoto
                            : product?.picture
                        }
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-evenly",
                          marginLeft: 15,
                        }}
                      >
                        <span>{product?.name}</span>
                        <span>{product?.description}</span>
                        <span>
                          ₺ {product?.price} - ₺ {product?.store_price}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#fff",
                        marginLeft: 10,
                        width: "20%",
                      }}
                    >
                      {actionType == "delete" ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "65%",
                            }}
                          >
                            <span
                              style={{
                                marginBottom: 4,
                                fontWeight: "bold",
                              }}
                            >
                              GÖSTER
                            </span>
                            <Divider
                              sx={{
                                backgroundColor: "#f4f4f4",
                                width: 1,
                              }}
                            />
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>App</span>
                                <GreenSwitch
                                  checked={product?.sepetapp}
                                  onClick={() => {
                                    let data = {
                                      product_id: product?.id,
                                      menuObj: {
                                        sepetapp: !product?.sepetapp,
                                      },
                                      restofficial_id: id,
                                    };
                                    menuDetailChangeFunc(data);
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <span style={{ fontWeight: "bold" }}>Masa</span>
                                <GreenSwitch
                                  checked={product?.store}
                                  onClick={() => {
                                    let data = {
                                      product_id: product?.id,
                                      menuObj: {
                                        store: !product?.store,
                                      },
                                      restofficial_id: id,
                                    };
                                    menuDetailChangeFunc(data);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <Divider
                            orientation="vertical"
                            sx={{
                              backgroundColor: "#f4f4f4",
                              width: "1%",
                              marginLeft: 2,
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              style={{ width: 48, height: 48 }}
                              onClick={() => {
                                setProductDeleteModal(true);
                                setDeleteType("product");
                                let data = {
                                  product_id: product?.id,
                                  menuObj: {
                                    is_deleted: true,
                                  },
                                  restofficial_id: id,
                                };
                                setCurrentHeader(data);
                              }}
                            >
                              <DeleteSweep />
                            </IconButton>
                            <Tooltip title="Ürün Kopyala">
                              <IconButton
                                style={{ width: 48, height: 48 }}
                                onClick={() => handleCopyProduct(product)}
                              >
                                <ContentCopyIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </>
                      ) : actionType == "openOrClose" ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: 14,
                            }}
                          >
                            App
                            <GreenSwitch
                              checked={product?.sepetapp_is_active}
                              onClick={() => {
                                let data = {
                                  product_id: product?.id,
                                  menuObj: {
                                    sepetapp_is_active:
                                      !product?.sepetapp_is_active,
                                  },
                                  restofficial_id: id,
                                };
                                menuDetailChangeFunc(data);
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: 14,
                            }}
                          >
                            Masa
                            <GreenSwitch
                              checked={product?.store_is_active}
                              onClick={() => {
                                let data = {
                                  product_id: product?.id,
                                  menuObj: {
                                    store_is_active: !product?.store_is_active,
                                  },
                                  restofficial_id: id,
                                };
                                menuDetailChangeFunc(data);
                              }}
                            />
                          </div>
                        </div>
                      ) : actionType == "price" ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              fontSize: 14,
                            }}
                          >
                            App
                            <InputBase
                              type="number"
                              spellCheck="false"
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              value={bulkList?.price}
                              inputProps={{ style: { textAlign: "center" } }}
                              style={{
                                fontSize: 14,
                                backgroundColor: "#f1f1f1",
                                width: 75,
                                marginTop: 5,
                                borderRadius: 4,
                              }}
                              placeholder={"Fiyat"}
                              onChange={(e) => {
                                const bulkListObj = Object.assign(
                                  [],
                                  JSON.parse(JSON.stringify({ ...bulkList }))
                                );
                                let index = bulkListObj.findIndex(
                                  (item) => item.id === product.id
                                );
                                if (index == -1) {
                                  bulkListObj.push({
                                    id: product?.id,
                                    price: parseFloat(e.target.value),
                                  });
                                } else {
                                  if (e.target.value == "") {
                                    if (bulkListObj[index].store_price) {
                                      delete bulkListObj[index]["price"];
                                    } else {
                                      bulkListObj.splice(index, 1);
                                    }
                                  } else {
                                    bulkListObj[index].price = parseFloat(
                                      e.target.value
                                    );
                                  }
                                }
                                setBulkList(bulkListObj);
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              marginLeft: 12,
                              fontSize: 14,
                            }}
                          >
                            Masa
                            <InputBase
                              type="number"
                              spellCheck="false"
                              onKeyPress={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              value={bulkList?.store_price}
                              inputProps={{ style: { textAlign: "center" } }}
                              style={{
                                fontSize: 14,
                                backgroundColor: "#f1f1f1",
                                width: 75,
                                marginTop: 5,
                                borderRadius: 4,
                              }}
                              placeholder={"Fiyat"}
                              onChange={(e) => {
                                const bulkListObj = Object.assign(
                                  [],
                                  JSON.parse(JSON.stringify({ ...bulkList }))
                                );

                                let index = bulkListObj.findIndex(
                                  (item) => item.id === product.id
                                );
                                if (index == -1) {
                                  bulkListObj.push({
                                    id: product?.id,
                                    store_price: parseFloat(e.target.value),
                                  });
                                } else {
                                  if (e.target.value == "") {
                                    if (bulkListObj[index].price) {
                                      delete bulkListObj[index]["store_price"];
                                    } else {
                                      bulkListObj.splice(index, 1);
                                    }
                                  } else {
                                    bulkListObj[index].store_price = parseFloat(
                                      e.target.value
                                    );
                                  }
                                }
                                setBulkList(bulkListObj);
                              }}
                            />
                          </div>
                        </div>
                      ) : actionType == "photoUpload" ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: 100,
                              minWidth: 100,
                              height: "100%",
                            }}
                          >
                            <img
                              onClick={() => {
                                if (
                                  !resDetailInfo.generic_product_pictures_set
                                ) {
                                  setOpenAllowPhotoModal(true);
                                } else {
                                  setProductPhotoModal(true);
                                }
                                setCurrentHeader(product);
                                setCurrentProductType("product");
                              }}
                              style={{
                                width: "80%",
                                height: "80%",
                                borderRadius: 10,
                                cursor: "pointer",
                              }}
                              src={uploadPhoto}
                              alt="uploadPhoto"
                            />
                          </div>
                        </div>
                      ) : actionType == "sidedish" ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            fontSize: 14,
                          }}
                        >
                          Yanında Ne Gider
                          <GreenSwitch
                            checked={product?.side_dish}
                            onClick={() => {
                              let data = {
                                product_id: product?.id,
                                menuObj: {
                                  side_dish: !product?.side_dish,
                                },
                                restofficial_id: id,
                              };
                              menuDetailChangeFunc(data);
                            }}
                          />
                        </div>
                      ) : actionType == "discount" ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            fontSize: 14,
                          }}
                        >
                          Fırsat Ürün
                          <GreenSwitch
                            checked={product?.promoted}
                            onClick={() => {
                              let data = {
                                product_id: product?.id,
                                menuObj: {
                                  promoted: !product?.promoted,
                                },
                                restofficial_id: id,
                              };
                              menuDetailChangeFunc(data);
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ))}
          </Box>
        ))}

      {actionType == "price" && (
        <Button
          onClick={() => {
            let bulkListObj = {
              products: bulkList,
              restofficial_id: id,
            };
            menuBulkUpdatePriceFunc(bulkListObj);
          }}
          variant="contained"
          size="medium"
          style={{
            backgroundColor: "green",
            alignSelf: "flex-start",
            marginTop: 20,
          }}
        >
          Güncelle
        </Button>
      )}

      <ProductImportModal
        openProductImportModal={openProductImportModal}
        setOpenProductImportModal={setOpenProductImportModal}
        restOfficialData={restOfficialData}
        platformList={platformList}
      />

      <AllowPhotoModal
        openAllowPhotoModal={openAllowPhotoModal}
        setOpenAllowPhotoModal={setOpenAllowPhotoModal}
        setProductPhotoModal={setProductPhotoModal}
      />
    </div>
  );
}

export default MenuList;
