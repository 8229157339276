import React from "react";
import { orderProductsImage } from "../../../../../components/converter/orderProductsImage";

const RenewalAverageOrders = ({ summaryRestDetailInfo }) => {
  function currencyFormat(num) {
    return (
      "₺ " +
      num
        .toFixed(2)
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    );
  }
  return (
    <div>
      <div
        style={{
          width: "100%",
          height: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0px 0px 4.5px 1px gray",
          fontFamily: "segoeuiB",
          fontSize: 13,
          fontVariant: "all-petite-caps",
        }}
      >
        Siparişler ( Son bir ay )
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        {summaryRestDetailInfo?.avarage_orders?.map((row1) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 5,
                backgroundColor: "#494343",
                margin: 5,
                borderRadius: 4,
                flexDirection: "column",
              }}
            >
              <img
                src={orderProductsImage(row1.name)}
                alt={row1?.name}
                style={{
                  width: 30,
                  height: 30,
                }}
              />

              <div
                style={{
                  fontFamily: "segoeuiRg",
                  fontSize: 13,
                }}
              >
                {row1?.order_count} Adet
              </div>
              <div
                style={{
                  fontFamily: "segoeuiRg",
                  fontSize: 13,
                }}
              >
                {currencyFormat(parseInt(row1?.order_amount))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RenewalAverageOrders;
